import React from 'react';
import { Boat, Check, Container, File, Loaded } from '.';
import { Info } from './Icons';

interface SteperProps {
    DateCration: string,
    DatePreparation: string,
    DateVoyage: string,
    dateNavire: string,
    dateExpect?: string,
    DateArrive: string,
}



const Steper = ({ DateCration, DatePreparation, DateVoyage, dateNavire, dateExpect = "", DateArrive }: SteperProps) => {
    console.log("sdfsf", DateCration);

    if (DateCration === null) {
        return (
            <div className="w-full flex flex-col justify-center items-center mb-5 ">
                <div className="loading relative flex xl:w-10/12 w-full justify-between">
                    <div className={`to_do z-0 w-1/5 relative flex  justify-center items-center animate-pulse`}>
                        <div className={` bg-gray-200 w-16 h-16 rounded-full flex justify-center items-center`} >
                            <File />
                        </div>
                    </div>
                    <div className={`to_do z-0 w-1/5 relative flex justify-center items-center animate-pulse`}>
                        <div className={` bg-gray-200  w-16 h-16 rounded-full flex justify-center items-center`} >
                            <Container />
                        </div>
                    </div>
                    <div className={`to_do z-0 w-1/5 relative flex justify-center items-center animate-pulse`}>
                        <div className={`bg-gray-200  w-16 h-16 rounded-full flex justify-center items-center`} >
                            <Loaded />
                        </div>
                    </div>
                    <div className={` to_do z-0 w-1/5 relative flex justify-center items-center animate-pulse`}>
                        <div className={`bg-gray-200 w-16 h-16 rounded-full flex justify-center items-center`} >
                            <Boat />
                        </div>
                    </div>
                    <div className={`to_do z-0 w-1/5 relative flex justify-center items-center animate-pulse`}>
                        <div className={`bg-gray-200 w-16 h-16 rounded-full flex justify-center items-center`} >
                            <Check />
                        </div>
                    </div>
                </div>
                <div className='mt-3 flex xl:w-10/12 w-full justify-between'>
                    <div className='w-1/5 relative flex  justify-center items-center animate-pulse'>
                        <div className="h-4 w-10/12 bg-gray-200  rounded"></div>
                    </div>
                    <div className='w-1/5 relative flex  justify-center items-center animate-pulse'>
                        <div className="h-4 w-10/12 bg-gray-200  rounded"></div>
                    </div>
                    <div className='w-1/5 relative flex justify-center items-center animate-pulse'>
                        <div className="h-4 w-10/12 bg-gray-200  rounded"></div>
                    </div>
                    <div className='w-1/5 relative flex justify-center items-center animate-pulse'>
                        <div className="h-4 w-10/12 bg-gray-200  rounded"></div>
                    </div>
                    <div className='w-1/5 relative flex justify-center items-center animate-pulse'>
                        <div className="h-4 w-10/12 bg-gray-200  rounded"></div>
                    </div>
                </div>
            </div >
        );
    } else {

        return (
            <div className="w-full flex md:flex-col flex-row justify-center items-center mb-5">
                <div className="steps relative flex md:flex-row flex-col xl:w-10/12  md:w-full  justify-between">
                    <div className={`${DateCration != null ? "done" : "in_progress"} z-0 md:w-1/5 w-full relative flex md:h-auto h-28 justify-center items-center`}>
                        <div className={` ${DateCration != null ? "bg-succes" : "bg-warning"} w-16 h-16 rounded-full flex justify-center items-center`} >
                            <File />
                        </div>
                    </div>
                    <div className={`${DateCration === null ? "to_do" : DateCration != null && DatePreparation === null ? "in_progress" : DatePreparation != null ? 'done' : "to_do"} z-0 md:w-1/5 w-full relative flex md:h-auto h-28 justify-center items-center`}>
                        <div className={` ${DateCration === null ? "bg-grey " : DateCration != null && DatePreparation === null ? "bg-warning" : DatePreparation != null ? 'bg-succes' : "bg-grey"}  w-16 h-16 rounded-full flex justify-center items-center`} >
                            <Container />
                        </div>
                    </div>
                    <div className={`${DatePreparation === null || DateCration === null ? "to_do" : DatePreparation != null && dateNavire === null ? "in_progress" : dateNavire != null ? 'done' : "to_do"} z-0 md:w-1/5 w-full relative flex md:h-auto h-28 justify-center items-center`}>
                        <div className={`${DatePreparation === null || DateCration === null ? "bg-grey " : DatePreparation != null && dateNavire === null ? "bg-warning" : dateNavire != null ? 'bg-succes' : "bg-grey"}  w-16 h-16 rounded-full flex justify-center items-center`} >
                            <Loaded />
                        </div>
                    </div>
                    <div className={`${dateNavire === null || DatePreparation === null || DateCration === null ? "to_do" : dateNavire != null && DateVoyage === null ? "in_progress" : DateVoyage != null ? 'done' : "to_do"} z-0 md:w-1/5 w-full relative flex md:h-auto h-28 justify-center items-center`}>
                        <div className={`${dateNavire === null || DatePreparation === null || DateCration === null ? "bg-grey " : dateNavire != null && DateVoyage === null ? "bg-warning" : DateVoyage != null ? 'bg-succes' : "bg-grey"}  w-16 h-16 rounded-full flex justify-center items-center`} >
                            <Boat />
                        </div>
                    </div>
                    <div className={`${DateVoyage === null || dateNavire === null || DatePreparation === null || DateCration === null ? "to_do" : DateVoyage != null && DateArrive === null ? "in_progress" : DateArrive != null ? 'done' : "to_do"} z-0 md:w-1/5 w-full relative flex md:h-auto h-28 justify-center items-center`}>
                        <div className={`${DateVoyage === null || dateNavire === null || DatePreparation === null || DateCration === null ? "bg-grey " : DateVoyage != null && DateArrive === null ? "bg-warning" : DateArrive != null ? 'bg-succes' : "bg-grey"} w-16 h-16 rounded-full flex justify-center items-center`} >
                            <Check />
                        </div>
                    </div>
                </div>
                <div className='md:mt-3 flex  md:flex-row flex-col xl:w-10/12 md:w-full  justify-between'>
                    <div className='px-4 md:w-1/5 w-full md:h-auto h-28 relative flex flex-col md:justify-start justify-center items-center'>
                        <p className='font-semibold md:text-base text-sm '>Effets en attente de chargement</p>
                        {DateCration != null ?
                            <p>(Le {(new Date(DateCration)).toLocaleDateString('en-GB')})</p> : null
                        }
                    </div>
                    <div className='px-4 md:w-1/5 w-full md:h-auto h-28 relative flex flex-col md:justify-start justify-center items-center'>
                        <p className='font-semibold md:text-base text-sm'>Effets chargés container en transit</p>
                        {DatePreparation != null ?
                            <p>(Le {(new Date(DatePreparation)).toLocaleDateString('en-GB')})</p>
                            : null}
                    </div>
                    <div className='px-4 md:w-1/5 w-full md:h-auto h-28 relative flex flex-col md:justify-start justify-center items-center'>
                        <p className='font-semibold md:text-base text-sm'>Container embarqué sur navire</p>
                        {dateNavire != null ?
                            <p>( Le {(new Date(dateNavire)).toLocaleDateString('en-GB')})</p>
                            : null}
                    </div>
                    <div className='px-4 md:w-1/5 w-full md:h-auto h-28 relative flex flex-col md:justify-start justify-center items-center'>
                        <p className='font-semibold md:text-base text-sm'>En cours d’acheminement </p>

                        {DateVoyage != null ?
                            <p>( Le {(new Date(DateVoyage)).toLocaleDateString('en-GB')})</p>
                            : null}
                        {dateExpect != null ?
                            <p>arrivée prévu le ({(new Date(dateExpect)).toLocaleDateString('en-GB')})</p>
                            : null}
                    </div>
                    <div className='px-4 md:w-1/5 w-full md:h-auto h-28 relative flex flex-col md:justify-start justify-center items-center'>
                        <p className='font-semibold md:text-base text-sm'>Arrivée du container</p>
                        {DateArrive != null ?
                            <p>(Le {(new Date(DateArrive)).toLocaleDateString('en-GB')})</p>
                            : null}
                    </div>
                </div>
            </div >
        );
    }
}
export default Steper