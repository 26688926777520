import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Gears, ShutDown } from '.';

import { useAppDispatch } from '../../app/hooks';
import { logout } from '../../features/clientSlice';
import { Download } from '.';

interface ToolBoxProps { }

const ToolBox = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    return (
        <div className='bg-white bg-opacity-80 rounded overflow-hidden h-full md:mt-0 mt-5'>
            <OverlayScrollbarsComponent
                options={{ scrollbars: { autoHide: 'leave' } }}
                className='deviant-scrollbars' style={{ height: "100%", width: "100%", maxHeight: "110%", maxWidth: "100%" }}>
                <div className='flex flex-col justify-between h-full'>
                    <div className='flex flex-col  justify-center items-center'>
                        <div className='flex justify-center items-center bg-greybtn w-full rounded-t py-4'>
                            <div className='flex text-white text-base'><Gears /><span className='pl-2'>Barre d'outils</span></div>
                        </div>

                        <Button customClass='w-full mb-3 text-left justify-between' label="Télécharger un document" variant='greybtn' transparent icon={<div className='bg-greybtn p-2 rounded-md'><Download /></div>} onClick={() => {
                            navigate("/telechargements")

                        }} ></Button>
                        <Button customClass='w-full mb-3 text-left justify-between' label="Joindre des documents" variant='greybtn' transparent icon={<div className='bg-greybtn p-2 rounded-md'><Download /></div>} onClick={() => {
                            navigate("/joindre-des-documents")
                        }} ></Button>
                        <div className='w-full px-3 mt-5'>
                            <Button customClass='w-full mb-3' label="Acheter en ligne" variant='greybtn' onClick={() => {
                                window.open("https://2sage-alba.fr/destinations/france/outre-mer/services/alba-box/", '_blank');
                            }} ></Button>
                            <Button customClass='w-full mb-3' label="Nous contacter" transparent border variant='greybtn' onClick={() => {
                                navigate("/contact")
                            }} ></Button>
                        </div>
                    </div>
                    <div className="flex w-full items-center justify-center mb-3">
                        <Button label='Déconnexion' variant='primary' transparent icon={<ShutDown />} onClick={() => {
                            navigate("/")
                            dispatch(logout())
                        }} ></Button>
                    </div>
                </div>
            </OverlayScrollbarsComponent>
        </div>
    );
}
export default ToolBox