import axios from 'axios'
//http://127.0.0.1:8000/api/
const instance = axios.create({
    baseURL: 'https://api.suivi.alba-demenagement.fr/api/',
    headers: { "Content-type": "application/json" }
});
export default instance;

//https://api.suivi.alba-demenagement.fr/api/
/* const instance = axios.create({
    baseURL: 'http://127.0.0.1:8000/api/',
    headers: { "Content-type": "application/json" }
}) ;
export default instance;*/