import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import logo from "../../../assets/img/Logos.png";
import { login } from '../../../features/clientSlice';
import { Login } from '../../../requests/Client';
import { Input, Button } from '../../customComponenets';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { log } from 'console';
import { InvalidCredentials, SomthingWentWrong } from '../../../alerts';

function ClientLogin() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    // loading state
    const [loading, setLoading] = useState(false)
    // formik validation
    const validationSchema = Yup.object().shape({
        code_client: Yup.string()
            .min(2, 'Trop court!')
            .max(50, 'Trop long!')
            .required('Obligatoire'),
        code_conteneur: Yup.string()
            .min(2, 'Trop court!')
            .max(50, 'Trop long!')
            .required('Obligatoire')
    });
    const formik = useFormik({
        initialValues: {
            code_client: '',
            code_conteneur: '',
        },
        onSubmit: values => {
            setLoading(true)
            Login(values.code_client, values.code_conteneur).then((res) => {
                setLoading(false)
                if (res.status == 200) {
                    dispatch(login({ code_client: values.code_client, code_suivi: values.code_conteneur }))
                } else {
                    InvalidCredentials("client");
                }
            }).catch(() => {
                setLoading(false)
                SomthingWentWrong()
            })
        },
        validationSchema
    });



    return (
        <div className='bg-client bg-cover h-screen'>
            <div className='h-full flex flex-col justify-center items-center'>
                <div className="bg-white bg-opacity-80 p-12 lg:w-1/3 md:w-1/2 w-full max-h-full rounded text-center flex flex-col items-center justify-between">
                    <img className='w-1/2' src={logo} alt='logo' />
                    <h1 className='2xl:pt-12 2xl:px-16 xl:10 pt-6 px-2 text-xl text-black'>SUIVEZ VOS EFFETS,<span className='font-bold'> OÙ VOUS ÊTES & QUAND VOUS VOULEZ</span></h1>
                    <h1 className='pt-6 text-xl text-primary font-bold'>S'Identifier / Authentifier</h1>
                    <form onSubmit={formik.handleSubmit} className='w-full'>
                        <Input animation label="Code Client" value={formik.values.code_client} name="code_client" onChange={formik.handleChange} error={formik.touched.code_client && formik.errors.code_client ? formik.errors.code_client : null} onBlur={formik.handleBlur} />
                        <p></p>
                        <Input animation label="Code de Suivi" value={formik.values.code_conteneur} name='code_conteneur' onChange={formik.handleChange} error={formik.touched.code_conteneur && formik.errors.code_conteneur ? formik.errors.code_conteneur : null} onBlur={formik.handleBlur} />

                        <p className='pt-6 text-sm text-left text-grey font-bold'>Un problème urgent concernant votre livraison ?</p>
                        <p className='text-sm text-left text-grey font-bold'>Contactez-nous au <span className='text-primary'>01 48 11 68 68</span></p>
                        <div className='2xl:pt-12 xl:pt-8 pt-6 flex justify-end'>
                            <Button label='Connexion' variant='primary' type="submit" loading={loading}></Button>
                        </div>

                    </form>

                </div>
            </div>
        </div>
    )
}

export default ClientLogin
