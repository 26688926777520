import { axios } from "../axios";


export const Login = (username, password) => {
    let data = {
        "username": username,
        "password": password
    }
    return axios.post(`login`, data);
}
export const GetAgent = (email:string) => {
    return axios.get(`agents?page=1&email=${email}`);
}
