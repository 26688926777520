import React, { ButtonHTMLAttributes } from 'react';
import Loader from 'react-loader-spinner';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    label: string,
    variant: "primary" | "warning" | "succes" | "info" | "greybtn",
    loading?: boolean,
    border?: boolean,
    transparent?: boolean,
    left?: boolean,
    icon?: JSX.Element,
    disabled?: boolean,
    customClass?: string
}

const Button = ({ label, variant, border, loading = false, left, icon, transparent, disabled = false, customClass, ...props }: ButtonProps) => {
    return (
        <button {...props} disabled={disabled} className={`${customClass} px-4 py-3 ${disabled ? "opacity-40 cursor-not-allowed bg-grey" : ""} ${!transparent ? "text-white shadow-lg bg-" + variant : "text-" + variant} ${border ? "border-2 border-" + variant : " border-transparent"} rounded-md border-2  font-bold outline-none flex items-center justify-center  2xl:text-base text-sm transform active:scale-75 transition-transform hover:opacity-80`}>
            {icon && left &&
                <div className='pointer-events-none mr-2'>
                    {icon}
                </div>
            }

            {loading ? <Loader type="Oval" color="#fff" height={20} width={20} /> : label}
            {icon && !left &&
                <div className='pointer-events-none ml-2'>
                    {icon}
                </div>
            }
        </button>

    );
}
export default Button