import React, { useCallback, useEffect, useState } from 'react';
import logo from "../../../assets/img/Logos.png";
import { Badge, Button, Cogs, Comments, ShutDown, Steper, Table, Modal, Input, ToolsBarAdmin } from '../../customComponenets';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone'
import * as XLSX from "xlsx"
import Select from 'react-select';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAppDispatch } from '../../../app/hooks';
import { logout } from '../../../features/userSlice';
import { AddContainer, GetAllContainers, AddClient, GetAllClients, SendMails, AddClients } from '../../../requests';
import { SMSnotSent, SomthingWentWrong } from '../../../alerts';
import { CustomError, SuccesfulyAdded } from '../../../alerts';
import Loader from 'react-loader-spinner';


interface ContainerInterfaceProps { }

const ContainerInterface = () => {
    const dispatch = useAppDispatch();
    // modal states
    const [importmodal, setImportModal] = useState(false)
    const [addUserModal, setAddUserModal] = useState(false)
    // excel data
    // import modal data
    const [excelData, setExcelData] = useState([])
    const [Number, setNumber] = useState("")
    const [Code, setCode] = useState("")
    const [codeClient, setCodeClient] = useState("")
    // client email list
    const [clientEmailList, setClientEmailList] = useState([])
    // client list
    const [clientList, setClientList] = useState([])
    const [newClientList, setNewClientList] = useState([])

    // lading state
    const [loading, setLoading] = useState(true)
    const [savingExcel, setSavingExcel] = useState(false)

    const [loadingText, setLoadingText] = useState("");

    // clinet modal data

    // client select
    const [Clients, setClients] = useState([]);
    const [containers, setContainers] = useState([]); //[ unit, pack ]

    const navigate = useNavigate()
    const [file, setFile] = useState(null);


    //client error handeling
    const [clientErrorExist, setClientErrorExist] = useState(false)
    const [clientErrorList, setClientErrorList] = useState([])


    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        setFile(acceptedFiles[0]);
    }, [])
    const { acceptedFiles, fileRejections, getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false, accept: 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

    const GetClients = () => {
        GetAllClients()
            .then(res => {


                let res_clients = [];
                let email_clients = [];
                console.log(res.data);

                setClientList(res.data)
                res.data.map((item) => {
                    email_clients.push(item.email)
                    res_clients.push({
                        value: item.id,
                        label: item.firstname + ' / ' + item.email,
                    })
                })
                setClients(res_clients)
                setClientEmailList(email_clients)
            })
            .catch(err => {
                SomthingWentWrong();


            })
    }
    useEffect(() => {
        GetClients()
        GetAllContainers()
            .then(res => {


                setContainers(res.data)
                setLoading(false)
            })
            .catch(err => {
                SomthingWentWrong();


            })
    }, [])



    const ERRORCOLUMN = [

        {
            Header: 'Num de ligne',
            accessor: 'line',
        },
        {
            Header: 'Erreur',
            accessor: 'reason',
        }
    ]
    const COLUMNS = [

        {
            Header: 'Num Conteneur',
            accessor: 'code',
        },
        {
            Header: 'Commandes associées',
            accessor: 'commandes',
            Cell: ({ row }) => (
                <div className='flex justify-center items-center'>
                    <p>{row.original.commandes.length} Commandes</p>
                </div>),
        },
        {
            Header: 'Code Suivi',
            accessor: 'codeSuivi',
        },
        {
            Header: 'Statut',
            accessor: 'dateCreation',
            Cell: ({ row }) => (
                <div className='flex justify-center items-center' >
                    <Badge label={row.original.dateArrive ? "Arrivé" : row.original.dateVoyage ? "En voyage" : row.original.dateNavire ? "Embarqué sur navire" : row.original.datePreparation ? "En préparation" : "Crée"} variant={row.original.dateArrive ? "succes" : row.original.dateVoyage ? "info" : row.original.dateNavire ? "info" : row.original.datePreparation ? "primary" : "warning"} />
                </div>),

        },
        {
            Header: 'Modification',
            Cell: ({ row }) => (
                <div className='flex justify-center items-center'>
                    <Button label='Mettre à jour' icon={<Cogs />} transparent variant='info'
                        onClick={() => {
                            navigate(`/details`, {
                                state: { id: row.original.id }
                            })
                        }} />
                </div>),

        },

    ]

    const MODALCOLUMNS = [
        {
            Header: 'PRIORITE',
            accessor: 'priority',
        },
        {
            Header: 'Nom',
            accessor: 'Nom',
        },
        {
            Header: 'Client',
            Cell: ({ row }) => (
                <div className='flex justify-center items-center min-w-400' >
                    <Select
                        onChange={selectedOption => {
                            updateFieldChanged(row.index, selectedOption)
                        }}
                        value={row.original.client}
                        options={Clients}
                        placeholder="Choisissez le client"
                        isClearable={true}
                        maxMenuHeight={150}
                        styles={{
                            control: base => ({
                                ...base,
                                textAlign: "start",
                                width: 450,
                            })
                        }}
                    />
                </div>
            ),
        },
        {
            Header: 'Libellé',
            accessor: 'libelle',
        },
        {
            Header: 'Descriptif',
            accessor: 'description',
        },
        {
            Header: 'Qté. Annoncée',
            accessor: 'quantityAnnounced',
        },
        {
            Header: 'qté chargée',
            accessor: 'quantityCharged',
        },
        {
            Header: 'zone de chargement',
            accessor: 'zone',
        },
        {
            Header: 'Vol.(m3)',
            accessor: 'volume',
        },
        {
            Header: 'Poids(kg)',
            accessor: 'poids',
        },
        {
            Header: 'commentaires',
            accessor: 'commentaire',
        }
    ]

    const findClientCodeOrEmailExists = (email, code, array) => {

        for (let index = 0; index < array.length; index++) {

            var reeee = array[index].email === email;

            /*  console.log(`old list email ::${array[index].email}::`, '/////', reeee); */


            if (array[index].email === email && array[index].code !== code && email !== undefined) {
                /*  console.log("existing mail , ", array[index].email); */
                return { res: true, reason: `email déjà existant pour un autre client : ${array[index].code}`, type: "email" }
            } else if (array[index].code === code && array[index].email !== email) {
                console.log("existing email , ", array[index].email);
                console.log(array[index].code);
                let msg = array[index].email !== undefined && email === undefined ? "ce client a un email déjà enregistré et vous avez entré un email vide. Veuillez changer l'email dans le fichier excel pour correspondre à l'email correcte" : array[index].email === undefined && email !== undefined ? "le client est enregistré sans email, veuillez mettre à jour manuellement son email sur la page de la liste des clients." : "Ce client est déjà enregistré avec une autre adresse e-mail."
                return { res: true, reason: msg, type: "code" };

            }
        }
        return { res: false, reason: "none", type: "none" }
    };


    // true if clinet exists false if not // if false add new clinets
    const findClinetExists = (email, code, array): boolean => {
        for (let index = 0; index < array.length; index++) {

            if (array[index].email === email && array[index].code === code) {
                return true;
            }

        }
        return false
    }

    // simply decompose the string and extract the clinet code based on the cc positon
    const getClientCode = (String): string => {

        let code = "CC_"
        for (let index = 2; index < String.length; index++) {
            if (String[index - 1] === " " && String[index - 2] !== "C") {
                break
            }
            if (!isNaN(String[index]) && String[index] !== " ") {
                code = code + String[index]
            }
        }
        return code
    }

    // checks for all new clinets in the new excel file
    const getNewClientsList = (data) => {
        setLoadingText("création des nouveaux utilisateurs")
        let NewClientList = []
        let ClientErrorExist: boolean = false
        let ClientErrorList: any = []
        return new Promise<any[]>((resolve, reject) => {
            data.map((item, index) => {
                console.log(`new mail ${index}::${item["email"]}:: ***************************************`);
                if (findClientCodeOrEmailExists(item["email"], getClientCode(item["descriptif"]), clientList).res) {
                    ClientErrorExist = true
                    let error = ""
                    if (findClientCodeOrEmailExists(item["email"], getClientCode(item["descriptif"]), clientList).type === "email") {
                        error = item["email"] + " => " + findClientCodeOrEmailExists(item["email"], getClientCode(item["descriptif"]), clientList).reason
                    } else {
                        error = getClientCode(item["descriptif"]) + " => " + findClientCodeOrEmailExists(item["email"], getClientCode(item["descriptif"]), clientList).reason
                    }
                    ClientErrorList.push({
                        line: index + 2,
                        reason: error
                    })
                }
                //check double clients in new list
                else if (!findClinetExists(item["email"], getClientCode(item["descriptif"]), NewClientList) && !findClinetExists(item["email"], getClientCode(item["descriptif"]), clientList)) {
                    NewClientList.push({
                        firstname: item["nom"],
                        email: item["email"] ? item["email"] : null,
                        phone: item["phone"] ? item["phone"] : null,
                        code: getClientCode(item["descriptif"]),
                    })
                }
            })

            if (ClientErrorExist) {
                setSavingExcel(false);
                //setImportModal(false)

                reject(ClientErrorList)
            } else {

                AddClients(NewClientList).then((res) => {
                    let res_clients = [];
                    setClientList(res.data)
                    // prepare for react select
                    res.data.map((item) => {
                        res_clients.push({
                            value: item.id,
                            label: item.firstname + ' / ' + item.email
                        })
                    })
                    setClients(res_clients)
                    resolve(res.data);

                })

            }
        })
    }

    // reading the excel file
    const ReadExcel = (file) => {
        setSavingExcel(true)
        setLoadingText("Lecture du fichier excel")

        let finalData = []
        const prommise = new Promise<any[]>((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.readAsArrayBuffer(file)

            fileReader.onload = (e) => {
                const bufferArray = e.target.result
                const wb = XLSX.read(bufferArray, { type: "buffer", cellDates: true, cellNF: false, cellText: false })

                const wsname = wb.SheetNames[0]
                const ws = wb.Sheets[wsname];

                const data = XLSX.utils.sheet_to_json(ws, { raw: false, dateNF: "DD-MM-YYYY" })

                console.log("excel first read :", data);

                getNewClientsList(data).then((CLientsList) => {
                    setLoadingText("préparation de la liste des commandes")
                    data.map((item, index) => {
                        let ClientItem = {
                            value: "",
                            label: ""
                        }

                        for (let index = 0; index < CLientsList.length; index++) {
                            if (getClientCode(item["descriptif"]) === CLientsList[index].code) {
                                let ClientName = CLientsList[index].email ? CLientsList[index].email : "pas d'email"
                                ClientItem = {
                                    value: CLientsList[index].id,
                                    label: CLientsList[index].firstname + ' / ' + ClientName,
                                }
                            }
                        }
                        finalData.push({
                            priority: item["date"],
                            Nom: item["nom"],
                            client: ClientItem,
                            libelle: item["libelle"],
                            description: item["descriptif"],
                            quantityAnnounced: item["qte_annoncee"],
                            quantityCharged: item["qte_chargee"],
                            zone: item["zone_de_chargement"],
                            volume: item["vol_(m3)"],
                            poids: item["poids_(kg)"],
                            commentaire: item["commentaires"],
                        })

                    });

                    resolve(finalData)
                }).catch((e) => {
                    setClientErrorExist(true);
                    setClientErrorList(e);

                })


            }
            fileReader.onerror = ((error) => {
                reject(error)

            })
        })
        prommise.then((execelData) => {


            setLoadingText("")
            setSavingExcel(false);
            setExcelData(execelData);
        })
    }

    const updateFieldChanged = (index, e) => {

        let newArr = [...excelData];
        newArr[index].client = e;

        setExcelData(newArr);
    }

    const closeModal = (data) => {
        setImportModal(false)
        setFile(null)
        setExcelData([])
        setClientErrorExist(false)
        setClientErrorList([])
        setContainers([...containers, data])
        setNumber("")
        setCode("")
        setSavingExcel(false)
    }


    const AddContaier = () => {


        if (excelData.length === 0) {
            ReadExcel(file)
        } else {

            let emptyClients = false;
            let i = 0;
            while (!emptyClients && i < excelData.length) {

                if (excelData[i].client === null) {
                    emptyClients = true;
                }
                i++;
            }
            if (emptyClients) {

                CustomError("Veuillez attribuer des clients à tous les commandes");
            } else {
                let orders = [];
                excelData.map((item) => {
                    orders.push({
                        "libelle": item.libelle,
                        "priority": item.priority,
                        "description": item.description,
                        "quantityAnnounced": parseInt(item.quantityAnnounced),
                        "quantityCharged": parseInt(item.quantityCharged),
                        "volume": parseFloat(item.volume),
                        "poids": parseFloat(item.poids),
                        "client": {
                            "id": item.client.value
                        },
                        "fullname": item.Nom,
                        "zone": item.zone,
                        "commentaire": item.commentaire
                    })
                })
                let newContainer = {
                    "code": Number,
                    "codeSuivi": Code,
                    "dateCreation": new Date(),
                    "commandes": orders
                }
                setLoadingText("Ajout de commandes")
                setSavingExcel(true)
                AddContainer(newContainer)
                    .then(res => {
                        SendMails(res.data.id).then((mailres) => {
                            SuccesfulyAdded("Contenaire ")
                            closeModal(res.data)
                        }).catch((mailerr) => {
                            SMSnotSent()
                            closeModal(res.data)
                        })

                    })
                    .catch(err => {
                        SomthingWentWrong()

                        setSavingExcel(false)
                    })

            }

        }
    }
    return (

        <div className='bg-client bg-cover h-screen'>
            <div className='h-full flex flex-col justify-evenly items-center'>
                <p className='text-sm text-center text-darkgrey font-bold'>Un problème urgent concernant votre livraison ? Contactez-nous au <span className='text-primary'>01 48 11 68 68</span></p>
                {/* re add opacity */}
                <div className="lg:w-5/6 md:w-11/12 w-full h-4/5 overflow-hidden  text-center rounded">
                    {/* <div className="lg:w-9/12 md:w-11/12 w-full h-4/5 overflow-hidden  text-center rounded"> */}
                    <OverlayScrollbarsComponent
                        options={{ scrollbars: { autoHide: 'leave' } }}
                        style={{ height: "100%", width: "100%", maxHeight: "110%", maxWidth: "100%" }}>
                        <div className='flex flex-wrap h-full'>
                            {/* RE INSERT  */}
                            <div className=' lg:w-5/6 xl:w-4/5 w-full h-full md:pr-4'>
                                {/* <div className='w-full h-full'> */}
                                <div className='bg-white bg-opacity-80 rounded w-full  h-full overflow-hidden '>
                                    <OverlayScrollbarsComponent
                                        options={{ scrollbars: { autoHide: 'leave' } }}
                                        className='deviant-scrollbars' style={{ height: "100%", width: "100%", maxHeight: "100%", maxWidth: "100%" }}>

                                        <div className='y-80 py-12 2xl:px-24 xl:px-12 lg:px-6 md:px-6 px-2  flex flex-col items-center justify-between'>
                                            <div className="flex w-full xl:flex-row mb-5 flex-col justify-between  items-center">
                                                <div className="flex w-full xl:flex-row flex-col  xl:items-center xl:justify-start justify-center">
                                                    <h1 className='text-2xl font-bold'>
                                                        Outil de suivi de déménagement
                                                    </h1>
                                                </div>
                                                <div className="flex w-full xl:justify-end items-center justify-center">
                                                    <Button label='Déconnexion' variant='primary' transparent icon={<ShutDown />} onClick={() => {
                                                        dispatch(logout())
                                                        navigate("/admin20alba21")
                                                    }} ></Button>
                                                </div>
                                            </div>
                                            <Table search COLUMNS={COLUMNS} DATA={containers} loading={loading} buttons={
                                                <div className='flex lg:justify-end justify-center'>
                                                    <div className="ml-2">
                                                        <Button label='IMPORTER XLS' variant='info' onClick={() => {
                                                            setImportModal(true);
                                                        }} ></Button>
                                                    </div>
                                                    <div className="ml-2">
                                                        <Button label='LISTE DES CLIENTS' variant='info' transparent border onClick={() => {
                                                            navigate("/Clients")
                                                        }} ></Button>
                                                    </div>
                                                </div>
                                            } />
                                        </div>
                                    </OverlayScrollbarsComponent>
                                </div>
                            </div>
                            <div className='lg:w-1/6 xl:w-1/5 w-full h-full'>
                                <ToolsBarAdmin />
                            </div>
                        </div>
                    </OverlayScrollbarsComponent>
                </div>
                <img className='w-36' src={logo} alt='logo' />
            </div>
            <Modal
                title='IMPORTER XLS'
                show={importmodal} close={() => { setImportModal(false); }}
                footer={
                    <div className='flex lg:justify-end justify-center'>
                        <div className="ml-2">
                            <Button onClick={AddContaier} label='Valider' variant='succes' disabled={file === null || Number === "" || Code == "" || savingExcel} />
                        </div>
                        <div className="ml-2">
                            <Button onClick={() => {
                                setImportModal(false)
                                setTimeout(() => {
                                    setImportModal(false)
                                    setFile(null)
                                    setExcelData([])
                                    setClientErrorExist(false)
                                    setClientErrorList([])
                                    setNumber("")
                                    setCode("")
                                    setSavingExcel(false)
                                }, 100);

                            }} label='Fermer' variant='primary' disabled={savingExcel} />
                        </div>
                    </div>}
                height={savingExcel ? "h-1/3" : clientErrorList.length != 0 ? "h-full" : excelData.length !== 0 ? "h-full" : ""}
            >

                {
                    savingExcel ? <div className='flex flex-col justify-center items-center h-full '><Loader type="Oval" color="#6896FF" height={80} width={80} /> {loadingText}</div>
                        : clientErrorList.length != 0 ?
                            <div className='h-5/6 p-5'>
                                <h2 className='pb-5 text-red-700 font-bold'>Vous avez des données à vérifié dans votre fichier</h2>
                                <Table COLUMNS={ERRORCOLUMN} DATA={clientErrorList} loading={false} />
                            </div>
                            :
                            excelData.length === 0 ?
                                <div className='p-5'>
                                    <div className='flex flex-col'>
                                        <label className="block text-sm font-medium text-gray-700 my-2">Numéro de conteneur *</label>
                                        <Input
                                            paddingClass='py-2'
                                            margintopClass='mt-0'
                                            shadow={false}
                                            label="Numéro"
                                            iconBgColor='bg-warning'
                                            value={Number}
                                            onChange={e => {
                                                setNumber(e.target.value)
                                                if (e.target.value != "") {
                                                    let code = e.target.value + '_' + Math.random().toString(36).substring(2, 9);
                                                    setCode(code.replaceAll(' ', '_'))
                                                } else {
                                                    setCode("")
                                                }

                                            }} />
                                        <label className="block text-sm font-medium text-gray-700 my-2">Code de suivi *</label>
                                        <Input
                                            paddingClass='py-2'
                                            margintopClass='mt-0'
                                            shadow={false}
                                            label="Code"
                                            iconBgColor='bg-warning'
                                            value={Code}
                                            disabled
                                        />
                                        <label className="block text-sm font-medium text-gray-700 my-2">Fichier a importer</label>
                                        <div {...getRootProps()} className={`cursor-pointer mt-1 flex justify-center px-6 pt-5 pb-6 border-2 ${isDragActive ? 'border-gray-700 bg-gray-200' : 'border-gray-300'}  border-dashed rounded-md`}>
                                            <div className="space-y-1 text-center">
                                                <svg
                                                    className="mx-auto h-12 w-12 text-gray-400"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    viewBox="0 0 48 48"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                        strokeWidth={2}
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                                <div className="flex text-sm  justify-center text-gray-600">
                                                    <label
                                                        htmlFor="file-upload"
                                                        className="relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                                    >
                                                        {
                                                            isDragActive && file !== null ?
                                                                null :
                                                                <span>Importer</span>
                                                        }

                                                        <input {...getInputProps()} id="file-upload" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" name="file-upload" type="file" className="sr-only" />
                                                    </label>
                                                    {
                                                        isDragActive ?
                                                            <p className="pl-1">Drop the files here ...</p> :
                                                            <p className="pl-1">ou glissez un fichier ici</p>
                                                    }

                                                </div>

                                                {file !== undefined && file !== null ?
                                                    <p className="text-base font-bold text-gray-500">{file.name}</p>
                                                    :
                                                    <p className="text-sm text-gray-500">
                                                        {
                                                            fileRejections.length != 0 ? <b className='text-primary' >nous n'acceptons que les fichiers excel <br></br> </b> : null
                                                        }
                                                        <b>xlsx</b> up to 10MB</p>
                                                }


                                            </div>
                                        </div>
                                    </div>
                                </div> :

                                <Table COLUMNS={MODALCOLUMNS} DATA={excelData} loading={false} />
                }
            </Modal>



        </div>
    );
}
export default ContainerInterface