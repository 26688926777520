import React, { useMemo } from 'react';

import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table';
import { Input, Button, Loading, Loop, SrotDown, SrotUp } from '.';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

interface TableProps {
    onClick?: () => void,
    search?: boolean,
    COLUMNS: any,
    DATA: any,
    title?: string,
    loading: any,
    buttons?: JSX.Element,
    maxHeight?: string,
    updateMyData,
    getColumnProps?,
    getCellProps?
}

// Create an editable cell renderer
const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
}) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue)

    const onChange = e => {
        setValue(e.target.value)
    }

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        updateMyData(index, id, value)
        console.log("changed finished");
    }

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    return <input className='p-2 rounded-sm border-2' value={value} onChange={onChange} onBlur={onBlur} />
}

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
    Cell: EditableCell,
}
const defaultPropGetter = () => ({});
const EditableTable = ({ onClick, search = false, COLUMNS, DATA, title, loading, buttons,
    maxHeight = "100%", updateMyData, getCellProps = defaultPropGetter,
    getColumnProps = defaultPropGetter, }: TableProps) => {
    const columns = useMemo(() => COLUMNS, [DATA])
    const data = useMemo(() => DATA, [DATA])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        headers,
        prepareRow,
        state,
        setGlobalFilter,

    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            // use the skipPageReset option to disable page resetting temporarily
            //autoResetPage: !skipPageReset,
            // updateMyData isn't part of the API, but
            // anything we put into these options will
            // automatically be available on the instance.
            // That way we can call this function from our
            // cell renderer!
            updateMyData,
        },
        useGlobalFilter,
        useSortBy
    )



    const { globalFilter } = state
    return (
        <div className="w-full h-full mb-5" >

            <div className='h-full'>
                
                {search ?
                    <div className={`flex lg:flex-row flex-col  ${buttons ? "justify-between" : "justify-end"} mb-10 mt-10`} >
                        <div className={'lg:w-2/4 w-full my-2'}>
                            <Input
                                paddingClass='py-2'
                                margintopClass='mt-0'
                                shadow={false}
                                label="Recherche"
                                icon={<Loop />}
                                iconBgColor='bg-warning'
                                value={globalFilter}
                                onChange={e => {
                                    setGlobalFilter(e.target.value);
                                }}
                            />
                        </div>
                        {
                            buttons ? buttons : null
                        }
                    </div> : null}
                    {
                    title ? <div className={'flex justify-between  mb-5'}>
                        <div>
                            <h4 className="text-darkgrey text-base font-medium">{title}</h4>
                        </div>
                    </div> : null
                }
                <div className='h-full react_table'>
                    <OverlayScrollbarsComponent
                        options={{ scrollbars: { autoHide: 'leave' } }}
                        className='react_table'
                        style={{ height: "100%", width: "100%", maxHeight: maxHeight, maxWidth: "100%" }}>
                        <table {...getTableProps()} className="w-full bg-transparent border-collapse relative">
                            <thead >
                                {headerGroups.map((headerGroup, index) => (
                                    <tr className='bg-lightgrey w-full z-10 sticky top-0' key={index} {...headerGroup.getHeaderGroupProps()} >
                                        {headerGroup.headers.map((column, index) => (
                                            <th key={index} {...column.getHeaderProps(column.getSortByToggleProps())} className={`${index === 0 ? "rounded-bl-btn rounded-tl-btn" : index === headers.length - 1 ? "rounded-tr-btn rounded-br-btn" : ""} px-2 py-3 text-grey align-middle text-sm whitespace-nowrap font-medium text-center `}>
                                                <span className='flex flex-row justify-center relative px-6'>
                                                    {column.render('Header')}
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? <div className='w-5 h-5 absolute right-0' > <SrotDown /></div>
                                                            : <div className='w-5 h-5 absolute right-0' ><SrotUp /></div>
                                                        : <div className='w-5 h-5 absolute right-0' ></div>}
                                                </span>

                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>

                            <tbody className='w-full' {...getTableBodyProps()}>

                                {loading ?
                                    <Loading rows={headers.length} />
                                    :
                                    rows.length != 0 ?
                                        rows.map((row, index) => {
                                            prepareRow(row)
                                            return (
                                                <tr key={index} {...row.getRowProps()} >
                                                    {row.cells.map((cell, index) => {
                                                        return <th key={index} {...cell.getCellProps([
                                                            {
                                                                className: cell.column.className,
                                                                style: cell.column.style
                                                            },
                                                            getColumnProps(cell.column),
                                                            getCellProps(cell)
                                                        ])} className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-center" >
                                                            {cell.render('Cell')}
                                                        </th>
                                                    })}
                                                </tr>
                                            )
                                        })
                                        :
                                        <tr>
                                            <th colSpan={headers.length} className="border-b border-gray-200 align-middle font-light text-sm text-grey whitespace-nowrap px-2 py-4 text-center" >
                                                Aucune ligne à afficher
                                            </th>

                                        </tr>
                                }

                            </tbody>
                        </table>
                    </OverlayScrollbarsComponent>
                </div>

            </div>
        </div >
    );
}
export default EditableTable