import { axios } from "../axios";

export const GetAllContainers = () => {
    return axios.get("conteneurs");
}
export const GetOneContainer = (id: number) => {
    return axios.get(`conteneurs/${id}`);
}
export const AddContainer = (container: any) => {
    return axios.post("conteneurs", container);
}
export const ValidateContainer = (id: number, state: string, dateExpectedArrival, dateArrive) => {
    return axios.put(`validate_status/${id}`, {
        "dateExpectedArrival": dateExpectedArrival,
        "dateArrival": dateArrive,
        "etat": state
    });
}
export const SendMails = (id: number) => {
    return axios.post("email", {
        "id_conteneur": id
    });
}
