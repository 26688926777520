import React, { useCallback, useEffect, useState } from 'react';
import logo from "../../../assets/img/Logos.png";
import { Badge, Button, Cogs, Comments, ShutDown, Steper, Table, Modal, Input, ToolsBar, Download, Back } from '../../customComponenets';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone'
import * as XLSX from "xlsx"
import Select from 'react-select';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { logout } from '../../../features/userSlice';
import { AddContainer, GetAllContainers, AddClient, GetAllClients, SendMails } from '../../../requests';
import { SomthingWentWrong } from '../../../alerts';
import { SuccesfulyAdded } from '../../../alerts';
import Loader from 'react-loader-spinner';
import { GetUserFiles, GetClientByCode, AddUserFile } from '../../../requests/index'
import { clientInfos } from '../../../features/clientSlice';

interface UploadedFilesInterfaceProps { }

const UploadedFiles = () => {
    const dispatch = useAppDispatch();
    const clientdetails = useAppSelector(clientInfos)

    // modal states
    const [importmodal, setImportModal] = useState(false)

    // files data
    const [filesList, setFilesList] = useState([])

    // lading state
    const [loading, setLoading] = useState(true)
    const [savingfile, setSavingfile] = useState(false)

    const navigate = useNavigate()
    // file state
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("")

    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        setFile(acceptedFiles[0]);
    }, [])

    const { acceptedFiles, fileRejections, getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false, accept: 'application/pdf, image/png, image/jpeg' })

    const GetClientFiles = () => {
        GetClientByCode(clientdetails.code_client)
            .then((res) => {
                GetUserFiles(res.data[0].id)
                    .then((files) => {
                        setLoading(false)
                        setFilesList(files.data)
                    })
                    .catch((fileserr) => {
                        setLoading(false)
                        console.log("err in getting user files:", fileserr);
                    })
            })
            .catch((err) => {
                setLoading(false)
            })
    }

    const Addfile = () => {
        setSavingfile(true)
        GetClientByCode(clientdetails.code_client)
            .then((res) => {
               
                const formData = new FormData();
                formData.append(
                    "imageFile",
                    file,
                );
                formData.append(
                    "label",
                    fileName,
                );
                formData.append(
                    "user",
                    res.data[0].id,
                );
                AddUserFile(formData)
                    .then((files) => {
                        setSavingfile(false)
                        setImportModal(false)
                        setFilesList([...filesList, files.data])
                        SuccesfulyAdded("Ficher")
                    })
                    .catch((fileserr) => {
                        setSavingfile(false)
                        console.log("err in adding user file:", fileserr);
                    })
            })
            .catch((err) => {
                setSavingfile(false)
                console.log(err);

            })
    }
    useEffect(() => {
        GetClientFiles()
    }, [])



    const COLUMNS = [

        {
            Header: 'Nom fichier',
            accessor: 'label',
        },
        {
            Header: 'date Creation',
            accessor: 'dateCreation',
        },
        {
            Header: 'Fichier',
            Cell: ({ row }) => (
                <div className='flex justify-center items-center'>
                    <a href={`${row.original.server + "/" + row.original.fileName}`}><div className='bg-greybtn p-2 rounded-md'><Download /></div></a>
                </div >),
        }
    ]








    return (

        <div className='bg-client bg-cover h-screen'>
            <div className='h-full flex flex-col justify-evenly items-center'>
                <p className='text-sm text-center text-darkgrey font-bold'>Un problème urgent concernant votre livraison ? Contactez-nous au <span className='text-primary'>01 48 11 68 68</span></p>
                {/* re add opacity */}
                <div className="lg:w-5/6 md:w-11/12 w-full h-4/5 overflow-hidden  text-center rounded">

                    <OverlayScrollbarsComponent
                        options={{ scrollbars: { autoHide: 'leave' } }}
                        style={{ height: "100%", width: "100%", maxHeight: "110%", maxWidth: "100%" }}>
                        <div className='flex flex-wrap h-full'>
                            <div className=' lg:w-5/6 xl:w-4/5 w-full h-full md:pr-4'>
                                <div className="bg-white bg-opacity-80 rounded w-full  h-full overflow-hidden ">

                                    <OverlayScrollbarsComponent
                                        options={{ scrollbars: { autoHide: 'leave' } }}
                                        className='deviant-scrollbars' style={{ height: "100%", width: "100%", maxHeight: "110%", maxWidth: "100%" }}>
                                        <div className='y-80 py-12 2xl:px-24 xl:px-12 lg:px-6 md:px-6 px-2  flex flex-col items-center justify-between'>
                                            <div className="flex w-full xl:flex-row mb-5 flex-col justify-between  items-center">
                                            <div className="flex w-full xl:flex-row mb-5 flex-col justify-between  items-center">
                                                <div className="flex w-1/6  xl:justify-start items-center justify-center">
                                                    <Button label='Retour' variant='primary' left transparent icon={<Back />} onClick={() => {
                                                        navigate("/conteneur")
                                                    }} />
                                                </div>
                                                <div className="w-4/6 text-center">
                                                    <h1 className='text-2xl font-bold text-center"'>
                                                    Joindre des documents
                                                    </h1>
                                                </div>
                                                <div className="w-1/6">
                                                </div>
                                            </div>
                                               
                                            </div>
                                            <Table search COLUMNS={COLUMNS} DATA={filesList} loading={loading} maxHeight='500px' buttons={
                                                <div className='flex lg:justify-end justify-center'>
                                                    <div className="ml-2">
                                                        <Button label='Ajouter fichier' variant='info' onClick={() => {
                                                            setImportModal(true);
                                                        }} ></Button>
                                                    </div>
                                                </div>
                                            } />
                                        </div>
                                    </OverlayScrollbarsComponent>
                                </div>
                            </div>
                            <div className='lg:w-1/6 xl:w-1/5 w-full h-full'>
                                <ToolsBar />
                            </div>
                        </div>
                    </OverlayScrollbarsComponent>
                </div>
                <img className='w-36' src={logo} alt='logo' />
            </div>
            <Modal
                title='Fichier'
                show={importmodal} close={() => { setImportModal(false); }}
                footer={
                    <div className='flex lg:justify-end justify-center'>
                        <div className="ml-2">
                            <Button onClick={Addfile} label='Valider' variant='succes' loading={savingfile} disabled={file == null || fileName == ""} />
                        </div>
                        <div className="ml-2">
                            <Button onClick={() => {
                                setImportModal(false)
                                setTimeout(() => {

                                    setFileName("")
                                    setFile(null)

                                }, 100);

                            }} label='Fermer' variant='primary' />
                        </div>
                    </div>}

            >

                {
                    savingfile ? <div className='flex justify-center items-center h-full '><Loader type="Oval" color="#6896FF" height={80} width={80} /></div>

                        :
                        <div className='p-5'>
                            <div className='flex flex-col'>
                                <label className="block text-sm font-medium text-gray-700 my-2">Nom du Fichier *</label>
                                <Input
                                    paddingClass='py-2'
                                    margintopClass='mt-0'
                                    shadow={false}
                                    label="Nom du Fichier"
                                    iconBgColor='bg-warning'
                                    value={fileName}
                                    onChange={e => {
                                        setFileName(e.target.value)

                                    }} />
                                <label className="block text-sm font-medium text-gray-700 my-2">Fichier a importer</label>
                                <div {...getRootProps()} className={`cursor-pointer mt-1 flex justify-center px-6 pt-5 pb-6 border-2 ${isDragActive ? 'border-gray-700 bg-gray-200' : 'border-gray-300'}  border-dashed rounded-md`}>
                                    <div className="space-y-1 text-center">
                                        <svg
                                            className="mx-auto h-12 w-12 text-gray-400"
                                            stroke="currentColor"
                                            fill="none"
                                            viewBox="0 0 48 48"
                                            aria-hidden="true"
                                        >
                                            <path
                                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                strokeWidth={2}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                        <div className="flex text-sm  justify-center text-gray-600">
                                            <label
                                                htmlFor="file-upload"
                                                className="relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                            >
                                                {
                                                    isDragActive && file !== null ?
                                                        null :
                                                        <span>Importer</span>
                                                }

                                                <input {...getInputProps()} id="file-upload" accept="application/pdf, image/png, image/jpeg" name="file-upload" type="file" className="sr-only" />
                                            </label>
                                            {
                                                isDragActive ?
                                                    <p className="pl-1">Drop the files here ...</p> :
                                                    <p className="pl-1">ou glissez un fichier ici</p>
                                            }

                                        </div>

                                        {file !== undefined && file !== null ?
                                            <p className="text-base font-bold text-gray-500">{file.name}</p>
                                            :
                                            <p className="text-sm text-gray-500">
                                                {
                                                    fileRejections.length != 0 ? <b className='text-primary' >nous n'acceptons que les fichiers PDF , image <br></br> </b> : null
                                                }
                                                <b>PDF, JPGE, PNG</b> up to 10MB</p>
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </Modal>



        </div>
    );
}
export default UploadedFiles