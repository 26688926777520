import React from 'react';
import { Routes, Route, BrowserRouter as Router, } from 'react-router-dom';
import AgentLogin from './components/screens/agent/AgentLogin';
import ClientLogin from './components/screens/client/ClientLogin';
import ClientFollow from './components/screens/client/ClientFollow';
import ContainerInterface from './components/screens/agent/ContainerInterface';
import ContainerDetails from './components/screens/agent/ContainerDetails';
import { useAppSelector } from './app/hooks';
import { userInfos } from './features/userSlice';
import { clientInfos } from './features/clientSlice';
import Contact from './components/screens/client/Contact';
import DownloadFiles from './components/screens/client/DownloadFiles';
import UploadedFiles from './components/screens/client/UploadedFiles';
import ClientsList from './components/screens/agent/ClientsList';
import ClientsDetails from './components/screens/agent/ClientsDetails';
import RelanceClients from './components/screens/agent/RelanceClients';
import HistoriqueRelance from './components/screens/agent/HistoriqueRelance';


function App() {

  const userdetails = useAppSelector(userInfos)
  const clientdetails = useAppSelector(clientInfos)

  const authAdmin = userdetails.token;
  const authClient = clientdetails.code_client;


  if (authAdmin != null) {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<ContainerInterface />} />
          <Route path="/details" element={<ContainerDetails />} />
          <Route path="/Clients" element={<ClientsList />} />
          <Route path="/Clients-details" element={<ClientsDetails />} />
          <Route path="/relance" element={<RelanceClients />} />
          <Route path="/historique" element={<HistoriqueRelance />} />
          <Route path="*" element={<ContainerInterface />} />
        </Routes>
      </Router>
    );
  } else if (authClient != null) {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<ClientFollow />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/telechargements" element={<DownloadFiles />} />
          <Route path="/joindre-des-documents" element={<UploadedFiles />} />
          <Route path="*" element={<ClientFollow />} />
        </Routes>
      </Router>
    );
  } else {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<ClientLogin />} />
          <Route path="/admin20alba21" element={<AgentLogin />} />
          <Route path="*" element={<ClientLogin />} />
        </Routes>
      </Router>
    );
  }

}

export default App;
