import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../app/store';


export interface UserState {
  token: string;
  refreshToken: string;
  email: string;
  role: string[];
}

const initialState: UserState = {
  token: null,
  refreshToken: null,
  email: null,
  role: [],
};


export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    login: (state, action) => {
      state.token = action.payload.token
      state.refreshToken = action.payload.refreshToken
      state.email = action.payload.email
      state.role = action.payload.role
    },
    logout: (state) => {
      state.token = null
      state.refreshToken = null
      state.email = null
      state.role = []
    },
    // Use the PayloadAction type to declare the contents of `action.payload`  PayloadAction<number>

  },

});

export const { login, logout, } = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const userInfos = (state: RootState) => state.user;

export default userSlice.reducer;
