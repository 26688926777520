import React, { useMemo } from 'react';

import { useTable, useSortBy, useGlobalFilter, useFilters } from 'react-table';
import { Input, Button, Loading, Loop, SrotDown, SrotUp } from './';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { matchSorter } from 'match-sorter'

interface TableProps {
    onClick?: () => void,
    search?: boolean,
    COLUMNS: any,
    DATA: any,
    title?: string,
    loading: any,
    buttons?: JSX.Element,
    maxHeight?: string,
}

function dateBetweenFilterFn(rows, id, filterValues) {
    let sd = new Date(filterValues[0]);
    let ed = new Date(filterValues[1]);
    console.log(rows)
    console.log( id)
    console.log( filterValues)
    return rows.filter(r => {
        var time = new Date(r.original.dateMail);
        console.log(time)
        console.log(ed)
        console.log( sd)
        if (filterValues.length === 0) return rows;
        return (time >= sd && time <= ed);
    });
}

dateBetweenFilterFn.autoRemove = val => !val;
const Table = ({ onClick, search = false, COLUMNS, DATA, title, loading, buttons, maxHeight = "100%" }: TableProps) => {
    const columns = useMemo(() => COLUMNS, [DATA])
    const data = useMemo(() => DATA, [DATA])


    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )
    function DefaultColumnFilter({
        column: { filterValue, preFilteredRows, setFilter },
    }) {
        const count = preFilteredRows.length

        return (
            <></>
        )
    }

    function fuzzyTextFilterFn(rows, id, filterValue) {
        return matchSorter(rows, filterValue, { keys: [row => row] })
    }

    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            dateBetween: dateBetweenFilterFn,   /*<- LIKE THIS*/
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            }
        }),
        []
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        headers,
        prepareRow,
        state,
        setGlobalFilter,
        preGlobalFilteredRows
    } = useTable(
        {
            columns,
            data,
            filterTypes,
            defaultColumn
        },
        useGlobalFilter,
        useFilters,
        useSortBy
    )

    const { globalFilter } = state
    return (
        <div className="w-full h-full mb-5" >

            <div className='h-full'>
                {
                    title ? <div className={'flex justify-between  mb-5'}>
                        <div>
                            <h2 className="text-darkgrey text-lg font-semibold">{title}</h2>
                        </div>
                    </div> : null
                }
                {search ?
                    <div className={`flex lg:flex-row flex-col  ${buttons ? "justify-between" : "justify-end"} mb-10 mt-10`} >
                        <div className={'lg:w-2/4 w-full my-2'}>
                            <Input
                                paddingClass='py-2'
                                margintopClass='mt-0'
                                shadow={false}
                                label="Recherche"
                                icon={<Loop />}
                                iconBgColor='bg-warning'
                                value={globalFilter}
                                onChange={e => {
                                    setGlobalFilter(e.target.value);
                                }}
                            />
                        </div>
                        {
                            buttons ? buttons : null
                        }
                    </div> : null}
                <div className='h-full react_table'>
                    <OverlayScrollbarsComponent
                        options={{ scrollbars: { autoHide: 'leave' } }}
                        className='react_table'
                        style={{ height: "100%", width: "100%", maxHeight: maxHeight, maxWidth: "100%" }}>
                        <table {...getTableProps()} className="w-full bg-transparent border-collapse relative">
                            <thead >
                                {headerGroups.map((headerGroup, index) => (
                                    <tr className='bg-lightgrey w-full z-10 sticky top-0' key={index} {...headerGroup.getHeaderGroupProps()} >
                                        {headerGroup.headers.map((column, index) => (

                                            <th key={index} {...column.getHeaderProps(column.getSortByToggleProps())} className={`${index === 0 ?
                                                "rounded-bl-btn rounded-tl-btn" : index === headers.length - 1 ? "rounded-tr-btn rounded-br-btn" : ""} 
                                            px-2 py-3 text-grey align-middle text-sm whitespace-nowrap font-medium text-center `}>
                                                <span className='flex flex-row justify-center relative px-6'>
                                                    {column.render('Header')}

                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? <div className='w-5 h-5 absolute right-0' > <SrotDown /></div>
                                                            : <div className='w-5 h-5 absolute right-0' ><SrotUp /></div>
                                                        : <div className='w-5 h-5 absolute right-0' ></div>}
                                                </span>
                                                <div className="mt-2">{column.canFilter ? column.render('Filter') : null}</div>
                                            </th>

                                        ))}
                                    </tr>
                                ))}
                            </thead>

                            <tbody className='w-full' {...getTableBodyProps()}>

                                {loading ?
                                    <Loading rows={headers.length} />
                                    :
                                    rows.length != 0 ?
                                        rows.map((row, index) => {
                                            prepareRow(row)
                                            return (
                                                <tr key={index} {...row.getRowProps()} >
                                                    {row.cells.map((cell, index) => {
                                                        return <th key={index} {...cell.getCellProps()} className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-center" >
                                                            {cell.render('Cell')}
                                                        </th>
                                                    })}
                                                </tr>
                                            )
                                        })
                                        :
                                        <tr>
                                            <th colSpan={headers.length} className="border-b border-gray-200 align-middle font-light text-sm text-grey whitespace-nowrap px-2 py-4 text-center" >
                                                Aucune ligne à afficher
                                            </th>

                                        </tr>
                                }

                            </tbody>
                        </table>
                    </OverlayScrollbarsComponent>
                </div>

            </div>
        </div >
    );
}
export default Table