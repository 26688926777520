import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Gears, ShutDown } from '.';

import { useAppDispatch } from '../../app/hooks';
import { logout } from '../../features/userSlice';
import { Download } from '.';
import { History, Home, Mail } from './Icons';

interface ToolBoxProps { }

const ToolBoxAdmin = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    return (
        <div className='bg-white bg-opacity-80 rounded overflow-hidden h-full md:mt-0 mt-5'>
            <OverlayScrollbarsComponent
                options={{ scrollbars: { autoHide: 'leave' } }}
                className='deviant-scrollbars' style={{ height: "100%", width: "100%", maxHeight: "110%", maxWidth: "100%" }}>
                <div className='flex flex-col justify-between h-full'>
                    <div className='flex flex-col  justify-center items-center'>
                        <div className='flex justify-center items-center bg-greybtn w-full rounded-t py-4'>
                            <div className='flex text-white text-base'><Gears /><span className='pl-2'>Barre d'outils</span></div>
                        </div>

                        <Button customClass='w-full mb-3 text-left justify-between' label="Accueil" variant='greybtn' transparent icon={<div className='bg-greybtn p-2 rounded-md'><Home /></div>} onClick={() => {
                            navigate("/")

                        }} ></Button>
                        <Button customClass='w-full mb-3 text-left justify-between' label="Relance clients" variant='greybtn' transparent icon={<div className='bg-greybtn p-2 rounded-md'><Mail /></div>} onClick={() => {
                            navigate("/relance")
                        }} ></Button>
                        <Button customClass='w-full mb-3 text-left justify-between' label="Historique relances" variant='greybtn' transparent icon={<div className='bg-greybtn p-2 rounded-md'><History /></div>} onClick={() => {
                            navigate("/historique")
                        }} ></Button>

                    </div>

                    <div className="flex w-full items-center justify-center mb-3">
                        <Button label='Déconnexion' variant='primary' transparent icon={<ShutDown />} onClick={() => {
                            dispatch(logout())
                            navigate("/admin20alba21")
                        }} ></Button>
                    </div>
                </div>
            </OverlayScrollbarsComponent>
        </div>
    );
}
export default ToolBoxAdmin