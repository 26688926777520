import React, { useCallback, useEffect, useState } from 'react';
import logo from "../../../assets/img/Logos.png";
import { Badge, Button, Cogs, Comments, ShutDown, Steper, Table, Modal, Input, Back, Forward } from '../../customComponenets';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone'
import * as XLSX from "xlsx"
import Select from 'react-select';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAppDispatch } from '../../../app/hooks';
import { logout } from '../../../features/userSlice';
import { AddContainer, GetAllContainers, AddClient, GetAllClients, SendMails, patchClients } from '../../../requests';
import { SomthingWentWrong } from '../../../alerts';
import { CustomError, SuccesfulyAdded } from '../../../alerts';
import Loader from 'react-loader-spinner';
import EditableTable from '../../customComponenets/EditableTable';
import Swal from 'sweetalert2';


interface ClientsListInterfaceProps { }

const ClientsList = () => {
    const dispatch = useAppDispatch();


    // lading state
    const [loading, setLoading] = useState(true)

    const [clientList, setClientList] = useState([]);

    const navigate = useNavigate()


    const GetClients = () => {
        GetAllClients()
            .then((res) => {
                console.log(res.data);

                setClientList(res.data)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
            }
            )
    }

    useEffect(() => {

        GetClients()

    }, [])



    const COLUMNS = React.useMemo(
        () => [

            {
                Header: 'Code Client',
                accessor: 'code',
                Cell: ({ row }) => (
                    <div className='flex justify-center items-center'>
                        {row.original.code}
                    </div>
                )
            },

            {
                Header: 'Nom',
                accessor: 'firstname',
                Cell: ({ row }) => (
                    <div className='flex justify-center items-center'>
                        {row.original.firstname}
                    </div>
                )
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Liste des Fichers',
                Cell: ({ row }) => (
                    <div className='flex justify-center items-center'>
                        <Button label='' variant='primary' left transparent icon={<Forward />} onClick={() => {
                            navigate(`/Clients-details`, {
                                state: { user: row.original }
                            })
                        }} />
                    </div>),
            }
        ],
        []
    )

    const updateMyData = (rowIndex, columnId, value) => {
        // We also turn on the flag to not reset the page
        //setSkipPageReset(true)
        setClientList(old =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    patchClients(row.id, value)
                        .then((res) => {
                            Swal.fire({
                                toast: true,
                                position: 'top-end',
                                icon: 'success',
                                title: 'Email modifié avec succès!',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            //setShowUpdateModal(false)


                        })
                        .catch(err => {
                            if (err.response.status == 500) {

                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: "L'e-mail que vous avez entré existe déjà !",
                                })
                                setTimeout(() => {
                                    GetClients()
                                }, 1000);
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'Une erreur de mise à jours est survenue !',
                                })
                            }
                        })
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    }
                }
                return row
            })
        )
    }

    return (
        <div className='bg-client bg-cover h-screen'>
            <div className='h-full flex flex-col justify-evenly items-center'>
                <p className='text-sm text-center text-darkgrey font-bold'></p>
                {/* re add opacity */}
                <div className="bg-white bg-opacity-80 overflow-hidden  lg:w-9/12 md:w-11/12 w-full h-4/5 rounded text-center">
                    <OverlayScrollbarsComponent
                        options={{ scrollbars: { autoHide: 'leave' } }}
                        className='deviant-scrollbars' style={{ height: "100%", width: "100%", maxHeight: "100%", maxWidth: "100%" }}>
                        <div className='y-80 py-12 2xl:px-24 xl:px-12 lg:px-6 md:px-6 px-2  flex flex-col items-center justify-between'>
                            <div className="flex w-full xl:flex-row mb-5 flex-col justify-between  items-center">
                                <div className="flex  xl:justify-start items-center justify-center">
                                    <Button label='Retour' variant='primary' left transparent icon={<Back />} onClick={() => {
                                        navigate("/conteneur")
                                    }} />
                                </div>
                                <div className="flex  xl:flex-row flex-col  xl:items-center xl:justify-start justify-center">
                                    <h1 className='flex justify-center items-center text-2xl font-bold'>
                                        Liste des Clients
                                    </h1>
                                </div>
                                <div className="flex  xl:justify-end items-center justify-center">
                                    <Button label='Déconnexion' variant='primary' transparent icon={<ShutDown />} onClick={() => {
                                        navigate("/admin20alba21")
                                        dispatch(logout())
                                    }} />
                                </div>
                            </div>
                            <EditableTable search COLUMNS={COLUMNS} DATA={clientList} loading={loading}
                                updateMyData={updateMyData} maxHeight='500px'
                                title="Vous pouvez modifier l-email du client en saisissant un nouveau e-mail et en cliquant en dehors du champs" />
                        </div>
                    </OverlayScrollbarsComponent>
                </div>
                <img className='w-36' src={logo} alt='logo' />
            </div>
        </div>
    );
}
export default ClientsList