import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Loop, Modal, ShutDown, Table, ToolsBarAdmin } from '../../customComponenets';
import logo from "../../../assets/img/Logos.png";
import { useAppDispatch } from '../../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../features/userSlice';
import { useDropzone } from 'react-dropzone';
import * as XLSX from "xlsx"
import Loader from 'react-loader-spinner';
import { GetTemplate, RelanceClientsReq } from '../../../requests';
import { SomthingWentWrong, SuccesfulyUpdated } from '../../../alerts';
import Swal from "sweetalert2"

const RelanceClients = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false)
    const [fileClients, setFileClients] = useState([])
    const [loadingText, setLoadingText] = useState("");
    const [templateMail, setTemplateMail] = useState("")
    const [showmodal, setShowModal] = useState(false)
    const [actionBtn, setActionBtn] = useState(false)

    const [totalSending, setTotalSending] = useState(0)
    const [nbrSent, setNbrSent] = useState(0)

    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        setFile(acceptedFiles[0]);
        ReadExcel(acceptedFiles[0]);
    }, [])

    const { acceptedFiles, fileRejections, getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false, accept: 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

    const COLUMNS = [

        {
            Header: 'Nom Client',
            accessor: 'nom',
            Cell: ({ row }) => (
                <div className='flex justify-center items-center'>
                    {row.original.nom}
                </div>
            )
        },

        {
            Header: 'Email',
            accessor: 'email',
            Cell: ({ row }) => (
                <div className='flex justify-center items-center'>
                    {row.original.email}
                </div>
            )
        },
        {
            Header: 'Numéro Devis',
            accessor: 'numero',
            Cell: ({ row }) => (
                <div className='flex justify-center items-center'>
                    {row.original.numero}
                </div>
            )
        },
        {
            Header: 'Montant',
            accessor: 'tarification',
            Cell: ({ row }) => (
                <div className='flex justify-center items-center'>
                    {row.original.tarification}
                </div>
            )
        },
        {
            Header: 'Date devis',
            accessor: 'date',
            Cell: ({ row }) => (
                <div className='flex justify-center items-center'>
                    {row.original.date}
                </div>
            )
        },
        {
            Header: 'Numéro Portable',
            accessor: 'n_portable',
            Cell: ({ row }) => (
                <div className='flex justify-center items-center'>
                    {row.original.n_portable}
                </div>
            )
        },
        {
            Header: 'Numéro Domicile',
            accessor: 'n_domicile',
            Cell: ({ row }) => (
                <div className='flex justify-center items-center'>
                    {row.original.n_domicile}
                </div>
            )
        },
        {
            Header: 'Numéro Professionel',
            accessor: 'n_prof',
            Cell: ({ row }) => (
                <div className='flex justify-center items-center'>
                    {row.original.n_prof}
                </div>
            )
        },

    ]

    useEffect(() => {
        renderMailTemplate()
    }, [])


    // reading the excel file
    const ReadExcel = (file) => {

        setLoadingText("Lecture du fichier excel")
        setLoading(true);

        const promise = new Promise<any[]>((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.readAsArrayBuffer(file)

            fileReader.onload = (e) => {
                const bufferArray = e.target.result
                const wb = XLSX.read(bufferArray, { type: "buffer", cellDates: true, cellNF: false, cellText: false })

                const wsname = wb.SheetNames[0]
                const ws = wb.Sheets[wsname];

                const data = XLSX.utils.sheet_to_json(ws, { raw: false, dateNF: "DD-MM-YYYY" })
                resolve(data);
            }
            fileReader.onerror = ((error) => {
                console.log(error);
                reject(error)
            })
        })
        promise.then((execelData) => {
            console.log("excel", execelData);
            let clients = [];

            execelData.map((item) => {
                clients.push({
                    "nom": item.Prénom ? item.Prénom : (item.Nom ? item.Nom : "n/c"),
                    "date": item["Date DV"] ? item["Date DV"] : "n/c",
                    "numero": item["Num DV"] ? item["Num DV"] : "n/c",
                    "adresse": item["Ville Liv"] ? item["Ville Liv"] : "n/c",
                    "tarification": item["Montant TTC"] ? item["Montant TTC"] : "n/c",
                    "email": item.Email ? (item.Email.indexOf(";") != -1 ? item.Email.substring(0, item.Email.indexOf(";")) : item.Email) : "",
                    "n_portable": item["Tél. Port."] ? (item["Tél. Port."][0] === "0" ? "+33" + item["Tél. Port."].substring(1) : item["Tél. Port."]).replace(/ /g, '') : null,
                    "n_domicile": item["Tél. Dom."] ? (item["Tél. Dom."][0] === "0" ? "+33" + item["Tél. Dom."].substring(1) : item["Tél. Dom."]).replace(/ /g, '') : null,
                    "n_prof": item["Tél. Prof."] ? (item["Tél. Prof."][0] === "0" ? "+33" + item["Tél. Prof."].substring(1) : item["Tél. Prof."]).replace(/ /g, '') : null,
                })
            })
            console.log("clients array : ", clients);
            setFileClients(clients);
            setLoadingText("")
            setLoading(false);
        })

    }
    const sliceIntoChunks = (arr, chunkSize) => {
        const res = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            res.push(chunk);
        }
        return res;
    }
    const send = arrayTosend =>
        new Promise(resolve =>
            RelanceClientsReq({ "fileClients": arrayTosend })
                .then((res) => {
                    resolve(res)
                }
                )
                .catch((err) => {
                    setActionBtn(false)
                    console.log("err ::", err);
                    SomthingWentWrong()
                    setLoading(false)
                    setFile(null);
                    setFileClients([])
                })
        );
    const envoieRelance = async () => {
        setActionBtn(true);

        let arrayOfChunks = sliceIntoChunks(fileClients, 50);
        setTotalSending(fileClients.length);

        for (let index = 0; index < arrayOfChunks.length; index++) {
            let test = await send(arrayOfChunks[index])
            let sentchunk = nbrSent + arrayOfChunks[index].length;
            setNbrSent(sentchunk)
            console.log("done", index, test);
            if (index + 1 === arrayOfChunks.length) {
                setNbrSent(fileClients.length)
                setLoading(false)
                setFile(null);
                setFileClients([])
                SuccesfulyUpdated("Mails envoyés avec succès")
                setActionBtn(false)
                Swal.fire({
                    title: 'Vous pouvez voir la liste des anciennes relances maintenant',
                    showCancelButton: true,
                    confirmButtonText: 'Ok',
                }).then((result) => {
                    setNbrSent(0);
                    setTotalSending(0);
                    if (result.isConfirmed) {
                        navigate("/historique")
                    }
                })
            }
        }


        /* setLoading(false)
        setFile(null);
        setFileClients([])
        SuccesfulyUpdated("Mails envoyés avec succès")
        setActionBtn(false)
        Swal.fire({
            title: 'Vous pouvez voir la liste des anciennes relances maintenant',
            showCancelButton: true,
            confirmButtonText: 'Ok',
        }).then((result) => {

            if (result.isConfirmed) {
                navigate("/historique")
            }
        }) */

    }

    const renderMailTemplate = () => {
        GetTemplate()
            .then((res) => {
                setTemplateMail(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }
    return (
        <div className='bg-client bg-cover h-screen'>
            <div className='h-full flex flex-col justify-evenly items-center'>
                <p className='text-sm text-center text-darkgrey font-bold'>Un problème urgent concernant votre livraison ? Contactez-nous au <span className='text-primary'>01 48 11 68 68</span></p>
                {/* re add opacity */}
                <div className="lg:w-5/6 md:w-11/12 w-full h-4/5 overflow-hidden  text-center rounded">
                    <OverlayScrollbarsComponent
                        options={{ scrollbars: { autoHide: 'leave' } }}
                        style={{ height: "100%", width: "100%", maxHeight: "110%", maxWidth: "100%" }}>
                        <div className='flex flex-wrap h-full'>

                            <div className=' lg:w-5/6 xl:w-4/5 w-full h-full md:pr-4'>
                                <div className='bg-white bg-opacity-80 rounded w-full h-full overflow-hidden '>
                                    <OverlayScrollbarsComponent
                                        options={{ scrollbars: { autoHide: 'leave' } }}
                                        style={{ height: "100%", width: "100%", maxHeight: "110%", maxWidth: "100%" }}>
                                        <div className="flex w-full xl:flex-row pt-5 mb-5 flex-col px-10 justify-evenly  items-start">
                                            <div className='y-80 mt-1 2xl:px-12 xl:px-12 lg:px-6 md:px-6 px-2  w-full flex flex-col items-center'>

                                                <div className="flex w-full xl:flex-row mb-5 flex-col justify-between  items-center ">
                                                    <div className="flex w-full xl:flex-row flex-col  xl:items-center xl:justify-start justify-center">
                                                        <h1 className='text-2xl font-bold'>
                                                            Relancez vos clients
                                                        </h1>
                                                    </div>
                                                    <div className="flex w-full xl:justify-end items-center justify-center">
                                                        <div className="mt-2 mb-6 flex justify-between  items-center">
                                                            <Button onClick={() => { setShowModal(true) }} label='Mail de relance' variant='succes' icon={<Loop />} />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div {...getRootProps()} className={`cursor-pointer w-full mt-1 mb-6 pt-5 px-6  pb-6 border-2 ${isDragActive ? 'border-gray-700 bg-gray-200' : 'border-gray-300'}  border-dashed rounded-md`}>
                                                    <div className="space-y-1 text-center">
                                                        <svg
                                                            className="mx-auto h-24 text-gray-400"
                                                            stroke="currentColor"
                                                            fill="none"
                                                            viewBox="0 0 48 48"
                                                            aria-hidden="true"
                                                        >
                                                            <path
                                                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                                strokeWidth={2}
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                        <div className="flex text-sm  justify-center text-gray-600">
                                                            <label
                                                                htmlFor="file-upload"
                                                                className="relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                                            >
                                                                {
                                                                    isDragActive && file !== null ?
                                                                        null :
                                                                        <span>Importer</span>
                                                                }

                                                                <input {...getInputProps()} id="file-upload" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" name="file-upload" type="file" className="sr-only" />
                                                            </label>
                                                            {
                                                                isDragActive ?
                                                                    <p className="pl-1">Drop the files here ...</p> :
                                                                    <p className="pl-1">ou glissez un fichier ici</p>
                                                            }

                                                        </div>

                                                        {file !== undefined && file !== null ?
                                                            <p className="text-base font-bold text-gray-500">{file.name}</p>
                                                            :
                                                            <p className="text-sm text-gray-500">
                                                                {
                                                                    fileRejections.length != 0 ? <b className='text-primary' >nous n'acceptons que les fichiers excel <br></br> </b> : null
                                                                }
                                                                <b>xlsx</b> up to 10MB</p>
                                                        }
                                                    </div>
                                                </div>

                                                {
                                                    loading ? <div className='flex flex-col justify-center items-center h-full '>
                                                        <Loader type="Oval" color="#6896FF" height={80} width={80} /> {loadingText}</div>
                                                        : <Table COLUMNS={COLUMNS} DATA={fileClients} loading={false} maxHeight={"500px"}
                                                            title={file ? "Liste des clients dans le fichier" : "Aucun fichier importé"} />
                                                }

                                                <div className="mt-2 mb-6 flex justify-between  items-center">
                                                    <Button onClick={envoieRelance} disabled={fileClients.length > 0 ? false : true} label='Envoyer'
                                                        loading={actionBtn} variant={fileClients.length > 0 ? 'succes' : 'greybtn'} />
                                                </div>
                                                <div className="mt-2 mb-6 flex justify-between  items-center">
                                                    <p>{(totalSending === 0) ? null : `Email envoyés : ${nbrSent} / ${totalSending}`} </p>
                                                </div>
                                            </div>
                                        </div>

                                    </OverlayScrollbarsComponent>
                                </div>
                            </div>
                            <div className='lg:w-1/6 xl:w-1/5 w-full h-full'>
                                <ToolsBarAdmin />
                            </div>
                        </div>
                    </OverlayScrollbarsComponent>
                </div>
                <img className='w-36' src={logo} alt='logo' />
            </div>
            <Modal
                title='Aperçu du mail'
                show={showmodal} close={() => { setShowModal(false); }}
            >
                <OverlayScrollbarsComponent
                    options={{ scrollbars: { autoHide: 'leave' } }}
                    style={{ width: "100%", maxHeight: "100%", maxWidth: "100%" }}>
                    <div className="flex flex-col items-center w-full">
                        <div className='mt-6 mb-6 border-2 border-gray-300 '
                            unselectable="on"
                            dangerouslySetInnerHTML={{ __html: templateMail }}>
                        </div>
                    </div>
                </OverlayScrollbarsComponent>
            </Modal>
        </div >
    )
}

export default RelanceClients