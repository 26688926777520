import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { useEffect, useState } from 'react';
import { Button, Loop, Modal, Table, ToolsBarAdmin } from '../../customComponenets';
import logo from "../../../assets/img/Logos.png";
import Loader from 'react-loader-spinner';
import { GetHistory, GetTemplate } from '../../../requests';

const HistoriqueRelance = () => {

    const [loading, setLoading] = useState(false)
    const [historyClients, setHistoryClients] = useState([])
    const [loadingText, setLoadingText] = useState("");

    useEffect(() => {
        getRelances()
    }, [])

    const COLUMNS = React.useMemo(
        () => [

            {
                Header: 'Date Relance',
                accessor: 'date_mail',
                Filter: SelectColumnFilter,
                Cell: ({ row }) => (
                    <div className='flex justify-center items-center'>
                        {new Date(row.original.date_mail).toLocaleDateString('en-GB')}
                    </div>
                )
            },
            {
                Header: 'Nom Client',
                accessor: 'nomClient',
                Cell: ({ row }) => (
                    <div className='flex justify-center items-center'>
                        {row.original.nomClient}
                    </div>
                )
            },

            {
                Header: 'Email',
                accessor: 'email',
                Filter: SelectTextFilter,
                Cell: ({ row }) => (
                    <div className='flex justify-center items-center'>
                        {row.original.email}
                    </div>
                )
            },
            {
                Header: 'Numéro Devis',
                accessor: 'numDevis',
                Cell: ({ row }) => (
                    <div className='flex justify-center items-center'>
                        {row.original.numDevis}
                    </div>
                )
            },
            {
                Header: 'Montant',
                accessor: '	montant',
                Cell: ({ row }) => (
                    <div className='flex justify-center items-center'>
                        {row.original.montant + "€"}
                    </div>
                )
            },
            {
                Header: 'Date devis',
                accessor: 'date_devis',
                Cell: ({ row }) => (
                    <div className='flex justify-center items-center'>
                        {row.original.date_devis}
                    </div>
                )
            },
        ],
        []
    )


    function SelectTextFilter({
        column: { filterValue, setFilter, preFilteredRows, id },
    }) {
        // Calculate the options for filtering
        // using the preFilteredRows
        const options = React.useMemo(() => {
            const options = new Set()
            preFilteredRows.forEach(row => {
                options.add(row.values[id])
            })
            return [...options.values()]
        }, [id, preFilteredRows])

        // Render a multi-select box
        return (
            <select
                value={filterValue}
                onChange={e => {
                    setFilter(e.target.value || undefined)
                }}
            >
                <option value="">Tout</option>
                {options.map((option, i) => (
                    <option key={i} value={option.toString()}>
                        {option}
                    </option>
                ))}
            </select>
        )
    }
    // This is a custom filter UI for selecting
    // a unique option from a list
    function SelectColumnFilter({
        column: { filterValue, setFilter, preFilteredRows, id },
    }) {
        // Calculate the options for filtering
        // using the preFilteredRows
        const options = React.useMemo(() => {
            const options = new Set()
            preFilteredRows.forEach(row => {
                options.add(row.values[id])
            })


            return [...options.values()]
        }, [id, preFilteredRows])

        // Render a multi-select box
        return (
            <select
                value={filterValue}
                onChange={e => {
                    console.log(e.target.value)
                    setFilter(e.target.value || undefined)
                }}
            >
                <option value="">Tout</option>
                {options.map((option, i) => (
                    <option key={i} value={option.toString()}>
                        {new Date(option.toString()).toLocaleDateString('en-GB')
                        }
                    </option>
                ))}
            </select>
        )
    }
    const getRelances = () => {
        setLoading(true)
        setLoadingText("Chargement des relances précédentes")
        GetHistory()
            .then((res) => {
                setLoading(false)
                setHistoryClients(res.data.reverse())
            })
            .catch((err) => {
                console.log(err);
                setLoading(false)
            })
    }


    return (
        <div className='bg-client bg-cover h-screen'>
            <div className='h-full flex flex-col justify-evenly items-center'>
                <p className='text-sm text-center text-darkgrey font-bold'>Un problème urgent concernant votre livraison ? Contactez-nous au <span className='text-primary'>01 48 11 68 68</span></p>
                {/* re add opacity */}
                <div className="lg:w-5/6 md:w-11/12 w-full h-4/5 overflow-hidden  text-center rounded">
                    <OverlayScrollbarsComponent
                        options={{ scrollbars: { autoHide: 'leave' } }}
                        style={{ height: "100%", width: "100%", maxHeight: "110%", maxWidth: "100%" }}>
                        <div className='flex flex-wrap h-full'>


                            <div className=' lg:w-5/6 xl:w-4/5 w-full h-full md:pr-4'>
                                <div className='bg-white bg-opacity-80 rounded w-full h-full overflow-hidden '>
                                    <OverlayScrollbarsComponent
                                        options={{ scrollbars: { autoHide: 'leave' } }}
                                        style={{ height: "100%", width: "100%", maxHeight: "110%", maxWidth: "100%" }}>
                                        <div className="flex w-full xl:flex-row pt-5 mb-5 flex-col px-10 justify-evenly  items-start">
                                            <div className='y-80 mt-1 2xl:px-12 xl:px-12 lg:px-6 md:px-6 px-2  w-full flex flex-col items-center'>

                                                <div className="flex w-full mt-4 xl:flex-row mb-6 flex-col justify-between  items-center ">
                                                    <div className="flex w-full xl:flex-row flex-col  xl:items-center xl:justify-start justify-center">
                                                        <h1 className='text-2xl font-bold'>
                                                            Historique des relances
                                                        </h1>
                                                    </div>

                                                </div>

                                                {
                                                    <Table search COLUMNS={COLUMNS} DATA={historyClients} loading={loading} maxHeight={"500px"} />
                                                }

                                            </div>

                                        </div>

                                    </OverlayScrollbarsComponent>
                                </div>
                            </div>
                            <div className='lg:w-1/6 xl:w-1/5 w-full h-full'>
                                <ToolsBarAdmin />
                            </div>
                        </div>
                    </OverlayScrollbarsComponent>
                </div>
                <img className='w-36' src={logo} alt='logo' />
            </div>

        </div >
    )
}

export default HistoriqueRelance