import React from 'react';

interface BadgeProps {
    label: string,
    variant: "primary" | "warning" | "succes" | "info"
}

const Badge = ({ label, variant }: BadgeProps) => {
    return (

        <div className={`px-2 py-1 ${"bg-" + variant} rounded-full text-white text-xs outline-none`}>
            {label}
        </div>
    );
}
export default Badge