import React, { useEffect, useState } from 'react';
import logo from "../../../assets/img/Logos.png";
import { Back, Button, Phone, ToolsBar, Email, Address, Input, Textarea } from '../../customComponenets';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { clientInfos, logout } from '../../../features/clientSlice';
import { GetClientByCode, GetContainer } from '../../../requests/Client';
import Loader from 'react-loader-spinner';
import { Download } from '../../customComponenets/Icons';
import { axios } from '../../../axios/index';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { SomthingWentWrong, SuccesfulyUpdated } from '../../../alerts';

const Contact = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const validationSchema = Yup.object().shape({
        nom: Yup.string()
            .min(2, 'Trop court!')
            .max(50, 'Trop long!')
            .required('Obligatoire'),
        subject: Yup.string()
            .min(2, 'Trop court!')
            .max(100, 'Trop long!')
            .required('Obligatoire'),
        phone: Yup.string()
            .length(8, 'Longueur égale à 8!')
            .required('Obligatoire'),
        message: Yup.string()
            .min(2, 'Trop court!')
            .required('Obligatoire'),
        email: Yup.string()
            .email('E-mail non valide')
            .required('Obligatoire'),
    });
    const formik = useFormik({
        initialValues: {
            nom: '',
            email: '',
            phone: '',
            subject: '',
            message: ''
        },
        onSubmit: values => {
            setLoading(true)
            let bodyMail = {
                "nom": values.nom,
                "email": values.email,
                "phone": values.phone,
                "subject": values.subject,
                "message": values.message
            }
            axios.post('/contact_mail', bodyMail)
                .then((res) => {
                    setLoading(false)
                    SuccesfulyUpdated("E-mail envoyé avec succès")
                    formik.resetForm()
                })
                .catch((err) => {
                    setLoading(false)
                    SomthingWentWrong()
                })
        },
        validationSchema
    });
    const [loading, setLoading] = useState(false)


    useEffect(() => {

    }, [])


    return (
        <div className='bg-client bg-cover h-screen'>
            <div className='h-full flex flex-col justify-evenly items-center'>
                <p className='text-sm text-center text-darkgrey font-bold'>Un problème urgent concernant votre livraison ? Contactez-nous au <span className='text-primary'>01 48 11 68 68</span></p>
                {/* re add opacity */}
                <div className="lg:w-5/6 md:w-11/12 w-full h-4/5 overflow-hidden  text-center rounded">
                    <OverlayScrollbarsComponent
                        options={{ scrollbars: { autoHide: 'leave' } }}
                        style={{ height: "100%", width: "100%", maxHeight: "110%", maxWidth: "100%" }}>
                        <div className='flex flex-wrap h-full'>
                            <div className=' lg:w-5/6 xl:w-4/5 w-full h-full md:pr-4'>
                                <div className='bg-white bg-opacity-80 rounded w-full  h-full overflow-hidden '>
                                    <OverlayScrollbarsComponent
                                        options={{ scrollbars: { autoHide: 'leave' } }}
                                        className='deviant-scrollbars' style={{ height: "100%", width: "100%", maxHeight: "110%", maxWidth: "100%" }}>
                                        <div className='y-80 py-12 2xl:px-24 xl:px-12 lg:px-6 md:px-6 px-2  flex flex-col items-center justify-between'>
                                            <div className="flex w-full xl:flex-row mb-5 flex-col justify-between  items-center">
                                                <div className="flex w-1/3  xl:justify-start items-center justify-center">
                                                    <Button label='Retour' variant='primary' left transparent icon={<Back />} onClick={() => {
                                                        navigate("/conteneur")
                                                    }} />
                                                </div>
                                                <div className="w-1/3 text-center">
                                                    <h1 className='text-2xl font-bold text-center"'>
                                                        Contact
                                                    </h1>
                                                </div>
                                                <div className="  w-1/3">
                                                </div>
                                            </div>
                                            <div className="flex w-full  mb-5 flex-col justify-between items-center">
                                                <div className='flex flex-row flex-wrap w-full'>
                                                    <div className='md:w-1/3 w-full  md:pr-4 mt-3'>
                                                        <div className='flex bg-white h-full rounded-md shadow-input p-5'>
                                                            <Address />
                                                            <div className='flex flex-col text-left ml-3'>
                                                                <span className='text-base text-darkgrey font-medium'>13 rue du Bois Moussay</span><span className='text-base text-grey font-medium'>93240 Stains</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='md:w-1/3 w-full  md:px-2 mt-3'>
                                                        <div className='flex bg-white h-full rounded-md shadow-input p-5'>
                                                            <Phone />
                                                            <div className='flex flex-col text-left ml-3'>
                                                                <span className='text-base text-darkgrey font-medium'> APPELEZ-NOUS</span><span className='text-base text-grey font-medium'>01 48 11 68 68</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='md:w-1/3 w-full  md:pl-4 mt-3'>
                                                        <div className='flex bg-white h-full rounded-md shadow-input p-5'>
                                                            <Email />
                                                            <div className='flex flex-col text-left ml-3'>
                                                                <span className='text-base text-darkgrey font-medium'>ÉCRIVEZ-NOUS</span><span className='text-base text-grey font-medium'>conseils@2sage-alba.com</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <form onSubmit={formik.handleSubmit} className='w-full'>
                                                    <div className='flex flex-row flex-wrap w-full'>
                                                        <div className='md:w-1/2 w-full  md:pr-3 mt-3'>
                                                            <Input animation label="Nom" value={formik.values.nom} name="nom" onChange={formik.handleChange} error={formik.touched.nom && formik.errors.nom ? formik.errors.nom : null} onBlur={formik.handleBlur} />
                                                        </div>
                                                        <div className='md:w-1/2 w-full  md:pl-3 mt-3'>
                                                            <Input animation label="Email" value={formik.values.email} name="email" onChange={formik.handleChange} error={formik.touched.email && formik.errors.email ? formik.errors.email : null} onBlur={formik.handleBlur} />
                                                        </div>
                                                    </div>
                                                    <div className='flex flex-row flex-wrap w-full'>
                                                        <div className='md:w-1/2 w-full  md:pr-3 mt-3'>
                                                            <Input animation label="Téléphone" value={formik.values.phone} name="phone" onChange={formik.handleChange} error={formik.touched.phone && formik.errors.phone ? formik.errors.phone : null} onBlur={formik.handleBlur} />
                                                        </div>
                                                        <div className='md:w-1/2 w-full  md:pl-3 mt-3'>
                                                            <Input animation label="Objet" value={formik.values.subject} name="subject" onChange={formik.handleChange} error={formik.touched.subject && formik.errors.subject ? formik.errors.subject : null} onBlur={formik.handleBlur} />
                                                        </div>
                                                    </div>
                                                    <div className='w-full'>

                                                        <Textarea animation label="Message" value={formik.values.message} name="message" onChange={formik.handleChange} error={formik.touched.message && formik.errors.message ? formik.errors.message : null} onBlur={formik.handleBlur} />
                                                    </div>
                                                    <div className='flex justify-end w-full mt-3'>
                                                        <Button label='Envoyer' variant='primary' type="submit" loading={loading} />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                    </OverlayScrollbarsComponent>
                                </div>
                            </div>
                            <div className='lg:w-1/6 xl:w-1/5 w-full h-full'>
                                <ToolsBar />
                            </div>
                        </div>
                    </OverlayScrollbarsComponent>
                </div>
                <img className='w-36' src={logo} alt='logo' />
            </div>
        </div >
    );
}
export default Contact