import React, { useEffect, useState } from 'react';

interface ModalProps {
    show: boolean
    title: string
    children?: JSX.Element
    height?: string
    width?: string
    footer?: JSX.Element
    close: () => void
}

const Modal = ({ show, title, height, width, children, footer, close }: ModalProps) => {
    const [Hide, setHide] = useState(true)
    const [animate, setAnimate] = useState(false)

    const handleKeyUp = (event) => {
       
        const target = event.target as Element;
       
        if (target.classList.contains("modal")) {

            close()
        }
    }

    useEffect(() => {
        window.addEventListener('mousedown', handleKeyUp);
        return () => {
            window.removeEventListener('mousedown', handleKeyUp);
        }
    }, [])

    useEffect(() => {
        if (show) {
            setHide(false)
            setTimeout(() => { setAnimate(true) }, 100);
        } else {
            setAnimate(false)
            setTimeout(() => { setHide(true) }, 500);
        }

    }, [show])

    return (

        <div  className={`modal ${Hide ? "hidden" : ""} ${animate ? "opacity-100" : "opacity-0"} absolute inset-0 bg-black bg-opacity-30 h-screen w-full flex flex-col justify-center items-center md:items-center pt-10 md:pt-0 transition-all duration-500`}>
            <div className={`bg-white min-w-2/6  max-w-9/12 max-h-5/6 ${height} ${animate ? "opacity-100" : "opacity-0"} transition-all duration-500 rounded  overflow-hidden`}>
                <div style={{ maxHeight: 57 }} className={`relative  w-full`}>
                    <div className='px-4 py-3 border-b border-gray-200 flex justify-between items-center'>
                        <h2 className="text-xl font-semibold text-gray-600">{title}</h2>
                        <button onClick={() => {
                            close()
                        }} className="text-gray-400 bg-transparent h hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" type="button">
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" ><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>
                </div>
                <div style={{ height: "calc(100% - 127px)" }} className={`relative  max-h-full`}>
                    <div className="w-full h-full">
                        {children}
                    </div>
                </div>
                <div style={{ maxHeight: 70 }} className={` border-t border-gray-200 px-4 py-3 w-full flex justify-end items-center`}>
                    {footer ?
                        <div className='  flex justify-between items-center'>
                            {footer}
                        </div> : null
                    }
                </div>
            </div>
        </div>
    );
}
export default Modal