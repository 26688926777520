import React, { useEffect, useState } from 'react';
import logo from "../../../assets/img/Logos.png";
import { Badge, Button, Comments, Gears, Info, Steper, Table, ToolsBar } from '../../customComponenets';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { clientInfos, logout } from '../../../features/clientSlice';
import { GetClientByCode, GetContainer } from '../../../requests/Client';
import Loader from 'react-loader-spinner';
import { Download } from '../../customComponenets/Icons';


const ClientFollow = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const clientdetails = useAppSelector(clientInfos)
    const [loading, setLoading] = useState(true)
    const [dateCreation, setDateCreation] = useState(null)
    const [datePreparation, setDatePreparation] = useState(null)
    const [dateVoyage, setDateVoyage] = useState(null)
    const [dateExpect, setDateExpect] = useState(null)
    const [dateNavire, setDateNavire] = useState(null)
    const [dateArrive, setDateArrive] = useState(null)
    const [containerData, setContainerData] = useState([])
    const [containerComment, setContainerComment] = useState([])
    const [clinetName, setClinetName] = useState("");
    const [ClientCode, setClientCode] = useState("");
    const [code, setCode] = useState(null)

    const DATA = [
        {
            col1: 'En cours',
            col2: '24/10/2019',
            col3: 'Lot(s)',
            col4: 'qs',
            col5: 'Woreazeld',
            col6: 'Wosdfsrld',
            col7: 'Wosdfsrld',
            col8: 'Wosdfsrld',
        },
        {
            col1: 'En cours',
            col2: '24/10/2019',
            col3: 'Demenagement',
            col4: 'Worsdld',
            col5: 'sdf',
            col6: 'Wosdfsrld',
            col7: 'Wosdfsrld',
            col8: 'Wosdfsrld',
        },
        {
            col1: 'En cours',
            col2: '24/10/2019',
            col3: 'Demenagement',
            col4: 'fsWorldsf',
            col5: 'Wosdfsrld',
            col6: 'Wosdfsrld',
            col7: 'Wosdfsrld',
            col8: 'Wosdfsrld',
        },
    ]
    const COLUMNS = [
        /*  {
             Header: 'Status',
             accessor: 'dateArrive',
             Cell: ({ row }) => (
                 <div className='flex justify-center items-center min-w-300' >
                     <span className="flex justify-center md:ml-3  px-3 items-center h-10 text-white bg-warning rounded-btn" >
                         {dateArrive ? "Arrivé" : "En Cours"} </span>
                 </div>),
         }, */
        {
            Header: 'Priorité',
            accessor: 'priority',
            Cell: ({ row }) => (
                <div className='flex justify-center items-center min-w-300' >
                    <p>{(new Date(row.original.priority)).toLocaleDateString('en-GB')}</p>
                </div>),
        },
        {
            Header: 'Libellé',
            accessor: 'libelle',
        },
        {
            Header: 'Descriptif',
            accessor: 'description',
        },
        {
            Header: 'Qté. Annoncée',
            accessor: 'quantityAnnounced',
        },
        {
            Header: 'Vol. (m3)',
            accessor: 'volume',
        },

    ]


    useEffect(() => {
        GetContainer(clientdetails.code_client, clientdetails.code_suivi)
            .then((res) => {
                console.log(res.data);

                setCode(res.data.code)
                setClientCode(res.data.user.code)
                setClinetName(res.data.user.firstname)
                setContainerData(res.data.commandes)
                setDateCreation(res.data.dateCreation ? res.data.dateCreation : null)
                setDatePreparation(res.data.datePreparation ? res.data.datePreparation : null)
                setDateVoyage(res.data.dateVoyage ? res.data.dateVoyage : null)
                setDateNavire(res.data.dateNavire ? res.data.dateNavire : null)
                setDateArrive(res.data.dateArrive ? res.data.dateArrive : null)
                setDateExpect(res.data.dateExpectedArrival ? res.data.dateExpectedArrival : "")
                setContainerComment(res.data.comments)
                setLoading(false);
            })
    }, [])


    return (
        <div className='bg-client bg-cover h-screen'>
            <div className='h-full flex flex-col justify-evenly items-center'>
                <p className='text-sm text-center text-darkgrey font-bold'>Un problème urgent concernant votre livraison ? Contactez-nous au <span className='text-primary'>01 48 11 68 68</span></p>
                {/* re add opacity */}
                <div className="lg:w-5/6 md:w-11/12 w-full h-4/5 overflow-hidden  text-center rounded">
                    <OverlayScrollbarsComponent
                        options={{ scrollbars: { autoHide: 'leave' } }}
                        style={{ height: "100%", width: "100%", maxHeight: "110%", maxWidth: "100%" }}>
                        <div className='flex flex-wrap h-full'>


                            <div className=' lg:w-5/6 xl:w-4/5 w-full h-full md:pr-4'>
                                <div className='bg-white bg-opacity-80 rounded w-full  h-full overflow-hidden '>
                                    <OverlayScrollbarsComponent
                                        options={{ scrollbars: { autoHide: 'leave' } }}
                                        className='deviant-scrollbars' style={{ height: "100%", width: "100%", maxHeight: "110%", maxWidth: "100%" }}>
                                        <div className='y-80 py-12 2xl:px-24 xl:px-12 lg:px-6 md:px-6 px-2  flex flex-col items-center justify-between'>
                                            <div className="flex w-full xl:flex-row mb-5 flex-col justify-center  items-center">
                                                <h1 className='flex justify-center items-center text-2xl font-bold'>
                                                    {clinetName && ClientCode ? clinetName + " / " + ClientCode : <Loader type="Oval" color="#b5b5b5" height={20} width={20} />}
                                                </h1>
                                                {/*  <h1 className='flex justify-center items-center text-2xl font-bold'>
                                                    Conteneur n° {code ? code : <Loader type="Oval" color="#b5b5b5" height={20} width={20} />}
                                                </h1> */}
                                                <span className={`flex justify-center md:ml-3  px-5 items-center h-10 text-white bg-warning rounded-btn`}>
                                                    {code ? dateArrive ? "Arrivé" : "En Cours" : <Loader type="Oval" color="#fff" height={20} width={20} />} </span>
                                            </div>

                                            <Steper DateCration={dateCreation} DatePreparation={datePreparation} DateVoyage={dateVoyage} dateNavire={dateNavire} DateArrive={dateArrive} dateExpect={dateExpect} />
                                            {dateArrive != null ?
                                                <div className='bg-primary text-white w-full rounded-md my-4 p-2 flex items-center'>
                                                    <Info />
                                                    <p className='ml-2 text-left'>Merci d’attendre l’appel de l’agence pour le retrait/livraison de vos effets</p>

                                                </div>
                                                : null}
                                            <Table title='Mes commandes :' COLUMNS={COLUMNS} DATA={containerData} loading={loading} maxHeight='500px' />
                                            <Comments title='Remarque livraison :' comments={containerComment} />
                                        </div>

                                    </OverlayScrollbarsComponent>
                                </div>
                            </div>
                            <div className='lg:w-1/6 xl:w-1/5 w-full h-full'>
                                <ToolsBar />
                            </div>
                        </div>
                    </OverlayScrollbarsComponent>
                </div>
                <img className='w-36' src={logo} alt='logo' />
            </div>
        </div >
    );
}
export default ClientFollow