import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../app/store';


export interface clientState {
  code_client: string;
  code_suivi: string;
}

const initialState: clientState = {
  code_client: null,
  code_suivi: null,
};


export const clientSlice = createSlice({
  name: 'client',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    login: (state, action) => {

      state.code_client = action.payload.code_client
      state.code_suivi = action.payload.code_suivi
    },
    logout: (state) => {
      state.code_client = null
      state.code_suivi = null
    },
    // Use the PayloadAction type to declare the contents of `action.payload`  PayloadAction<number>

  },

});

export const { login, logout } = clientSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const clientInfos = (state: RootState) => state.client;

export default clientSlice.reducer;
