import React from 'react';
import { Badge } from '.';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
interface CommnetsProps {
    title: string,
    comments: any[]
}

const Commnets = ({ title, comments }: CommnetsProps) => {
    return (
        <div className='w-full pt-3 mb-5 bg-lightgrey rounded-btn'>
            <div className='flex flex-col'>
                <div className='text-left mb-3 px-3 ' >
                    <h2 className="text-darkgrey text-lg font-semibold">{title}</h2>
                </div>
                {comments.length != 0 ?

                    comments.map((item, index) => (
                        <div key={index} className='flex flex-row py-3  px-3  items-start'>
                            <Badge label={(new Date(item.dateCreation)).toLocaleDateString('en-GB')} variant='primary' />
                            <span className='pl-3 text-base text-left'>{item.message}</span>
                        </div>
                    ))
                    :
                    <span className='pl-3 pb-3 text-base text-left text-grey'>Aucun commentaire à afficher</span>
                }

            </div>
        </div>
    );
}
export default Commnets