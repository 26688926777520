import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import logo from "../../../assets/img/Logos.png";
import { login } from '../../../features/userSlice';
import { Input, Button } from '../../customComponenets';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Login } from '../../../requests/Agent';
import { InvalidCredentials, SomthingWentWrong } from '../../../alerts';

function AgentLogin() {
    const dispatch = useAppDispatch();

    // laoding state
    const [loading, setLoading] = useState(false)
    // formik validation
    const validationSchema = Yup.object().shape({
        userName: Yup.string()
            .min(2, 'Trop court!')
            .max(50, 'Trop long!')
            .required('Obligatoire'),
        password: Yup.string()
            .min(2, 'Trop court!')
            .max(50, 'Trop long!')
            .required('Obligatoire')
    });
    const formik = useFormik({
        initialValues: {
            userName: '',
            password: '',
        },
        onSubmit: values => {
            setLoading(true)
            Login(values.userName, values.password).then((res) => {
                setLoading(false)
                if (res.status == 200) {
                    navigate('/')
                    dispatch(login({ token: res.data.token, refreshToken: res.data.refresh_token, email: res.data.data.email, role: res.data.data.roles }))
                } else {

                }
            }).catch((err) => {
                setLoading(false)

                if (err.response.status == 401) {
                    InvalidCredentials("agent");
                } else {
                    SomthingWentWrong()
                }
            })
        },
        validationSchema
    });
    const navigate = useNavigate()

    return (
        <div className='bg-admin bg-cover h-screen'>
            <div className='h-full flex flex-col justify-center items-center'>
                <div className="bg-white bg-opacity-80 p-12 lg:w-1/3 md:w-1/2 w-full max-h-full rounded text-center flex flex-col items-center justify-between">
                    <img className='w-1/2' src={logo} alt='logo' />
                    <h1 className='pt-12 text-xl text-primary font-bold'>Connecter a votre espace Agent</h1>
                    <form onSubmit={formik.handleSubmit} className='w-full'>
                        <Input animation label="Agent Email" value={formik.values.userName} onChange={formik.handleChange} onBlur={formik.handleBlur} name='userName' error={formik.touched.userName && formik.errors.userName ? formik.errors.userName : null} />
                        <Input animation label="Mots de passe" type="password" value={formik.values.password} onChange={formik.handleChange} onBlur={formik.handleBlur} name='password' error={formik.touched.password && formik.errors.password ? formik.errors.password : null} />
                        <div className='pt-12 flex justify-end'>
                            <Button label='Connexion' variant='primary' type='submit' loading={loading} ></Button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    )
}

export default AgentLogin
