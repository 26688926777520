import { useEffect, useState } from 'react';
import logo from "../../../assets/img/Logos.png";
import { Button, ShutDown, Table, Back, Download } from '../../customComponenets';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../../app/hooks';
import { logout } from '../../../features/userSlice';



interface ClientsDetailsInterfaceProps { }

const ClientsDetails = () => {
    const dispatch = useAppDispatch();


    // lading state
    const [loading, setLoading] = useState(true)

    const [fileList, setFileList] = useState([])

    const navigate = useNavigate()
    const { state } = useLocation();

    //userinfo 

    const [firstName, setFirstName] = useState("")
    const [email, setEmail] = useState("")
    const [codeClient, setCodeClient] = useState("")

    const GetClient = () => {
        setFirstName(state.user.firstname);
        setEmail(state.user.email);
        setCodeClient(state.user.code);
        setLoading(false);
    }
    useEffect(() => {
        if (state != null || state != undefined) {
            GetClient()
        } else {
            // uncoment this for realod problems when no id is found
            //navigate("/")
        }
    }, [state])



    const COLUMNS = [

        {
            Header: 'Nom fichier',
            accessor: 'label',
        },
        {
            Header: 'date Creation',
            accessor: 'dateCreation',
        },
        {
            Header: 'Fichier',
            Cell: ({ row }) => (
                <div className='flex justify-center items-center'>
                    <a href={`${row.original.server + "/" + row.original.fileName}`}><div className='bg-greybtn p-2 rounded-md'><Download /></div></a>
                </div >),
        }
    ]


    return (

        <div className='bg-client bg-cover h-screen'>
            <div className='h-full flex flex-col justify-evenly items-center'>
                <p className='text-sm text-center text-darkgrey font-bold'></p>
                {/* re add opacity */}
                <div className="bg-white bg-opacity-80 overflow-hidden  lg:w-9/12 md:w-11/12 w-full h-4/5 rounded text-center">
                    <OverlayScrollbarsComponent
                        options={{ scrollbars: { autoHide: 'leave' } }}
                        className='deviant-scrollbars' style={{ height: "100%", width: "100%", maxHeight: "100%", maxWidth: "100%" }}>
                        <div className='y-80 py-12 2xl:px-24 xl:px-12 lg:px-6 md:px-6 px-2  flex flex-col items-center justify-between'>
                            <div className="flex w-full xl:flex-row mb-5 flex-col justify-between  items-center">
                                <div className="flex  xl:justify-start items-center justify-center">
                                    <Button label='Retour' variant='primary' left transparent icon={<Back />} onClick={() => {
                                        navigate("/Clients")
                                    }} />
                                </div>
                                <div className="flex  xl:flex-row flex-col  xl:items-center xl:justify-start justify-center">
                                    <h1 className='flex justify-center items-center text-2xl font-bold'>
                                        {state != null || state != undefined ? firstName + " / cc : " + codeClient : null}
                                    </h1>

                                </div>
                                <div className="flex  xl:justify-end items-center justify-center">
                                    <Button label='Déconnexion' variant='primary' transparent icon={<ShutDown />} onClick={() => {
                                        navigate("/admin20alba21")
                                        dispatch(logout())
                                    }} />
                                </div>
                            </div>
                            {state != null || state != undefined ?
                                <Table search COLUMNS={COLUMNS} DATA={state.user.savedFiles} loading={loading} maxHeight='500px' />
                                : null}
                        </div>

                    </OverlayScrollbarsComponent>
                </div>
                <img className='w-36' src={logo} alt='logo' />
            </div>


        </div>
    );
}
export default ClientsDetails