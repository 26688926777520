import { axios } from "../axios";

export const AddClient = (firstName, email, code) => {
    let data = {
        "firstname": firstName,
        "email": email,
        "code": code
    }

    return axios.post("clients", data);
}
export const AddClients = (data) => {
    console.log(data);


    const formData = new FormData();
    formData.append(
        "users",
        JSON.stringify(data),
    )
    return axios.post("post_users", formData, { headers: { "Content-Type": "multipart/form-data" } });
}
export const GetAllClients = () => {
    return axios.get(`clients`
        /* , {
            headers: { "Authorization": `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2NDAxODYzNjQsImV4cCI6MTY0MDE4OTk2NCwicm9sZXMiOlsiUk9MRV9VU0VSIl0sInVzZXJuYW1lIjoidGVzdEB0ZXN0LmNvbSJ9.feqQGkl8FwSt4hzp5Bin8ID631-UxCcuutY0oywUJkcHH6EI9cFvaxZGp1MIZbrGigezzUvB6yYYWLom8YiuDjfwc9ZvMLGffvFyIlMKMd33-KH1EPydnBsUekPzNqk_XOOIbcpVT_l9cQWtWrzIwBiYRuZDuQi8SxqDRWv50iWgQhAnKPPvMZtZjScULAo0nmex8amuIZhFuw5SkSydUALpWOnYTcw0PFNSZkEoVgrYwxdqiRbX8n2-ec4WYG7-GkycklG25B1chcOTCxr0k9X55QvfwGw3RFEdABNXNvIT-Ok65BmZVBC4ERmX8RnbWptGO3vJcd5uvWZHv_ZjR0dhgacUH2sv5C0B4oNRcxcPZfiSXsgqbL3e-YCYi_KyOsjyxjXdrzKr4VWzbVPDXhlfWquNrqVm-EoGWgXuCD2G7CyFHF3jD4ncHnOKKrkF1f6i1aYuQU61OmwyFcnNRr6S_54Z8-_tVM_lJ49XoDaGblsNjAeWsvYJPZCKN3GlHhDzSfjlE0D9ozn20wUypVzuux8a-yxzfp0-yRUKeJcJbOIfqcts6jIxGxEVAMRqqxBecdVspolI9yT4c4W1GY-D6oAlkAcwh4ACpjbPyoqpPROkHkDjZHrblI3u4qKRRBS8fxZXEi5K7orp54IKpy9rNEaRLFf5Pt-oyT-07b8` }
        } */
    );
}

export const Login = (code_client, code_conteneur) => {
    let data = {
        "code": code_client,
        "code_conteneur": code_conteneur
    }
    return axios.post(`login_client`, data);
}

export const GetContainer = (code_client, code_conteneur) => {
    let data = {
        "code": code_client,
        "code_conteneur": code_conteneur
    }
    return axios.post(`container_client`, data);
}

export const GetClientByCode = (code: string) => {
    return axios.get(`clients?page=1&code=${code}`);
}
export const patchClients = (id: Number, mail: string) => {
    return axios.patch(`clients/${id}`, { "email": mail },
        { headers: { "content-type": "application/merge-patch+json" } })
}