import { axios } from "../axios";

export const RelanceClientsReq = (list: any) => {
    return axios.post("relance_clients", list);
}
export const GetTemplate = () => {
    return axios.get("relance_template");
}
export const GetHistory = () => {
    return axios.get("historique_relances");
}