import React, { InputHTMLAttributes, useState } from 'react'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string,
    shadow?: Boolean,
    paddingClass?: string,
    margintopClass?: string,
    animation?: boolean,
    icon?: JSX.Element,
    iconBgColor?: string,
    error?: string,
}
const Input = ({ label, shadow = true, paddingClass, margintopClass, animation, icon, iconBgColor, error, ...props }: InputProps) => {
    const [focused, setFocused] = useState(false)
    const onFocus = () => setFocused(true)
    const onBlur = () => setFocused(false)
    let { value } = props
    return (
        <div className={`${margintopClass ? margintopClass : "mt-5"} ${shadow ? "shadow-input bg-white rounded-input" : "bg-lightgrey rounded-btn"}   flex flex-row  items-center`}>
            <div className="w-full relative">
                <input {...props} onFocus={onFocus} onBlurCapture={onBlur} className={`bg-transparent w-full px-6 ${paddingClass ? paddingClass : "pt-8 pb-3"}  focus:outline-none active:outline-none `} />
                <label className={`pointer-events-none h-full absolute mb-0 left-0 pl-6 leading-tighter text-gray-400 cursor-text ${animation ? "transition-all duration-200 ease-in-out" : ""} ${animation ? (focused || (value !== "" && value !== undefined) ? "pt-2 text-sm" : "pt-6 text-base") : (value !== "" && value !== undefined ? paddingClass + " opacity-0 text-sm" : paddingClass + " text-sm")}`}>{label}<label className='text-primary text-xs pl-2' >{error ? error : null}</label></label>
            </div>
            {icon && iconBgColor ?
                <div className={`${iconBgColor} ${paddingClass ? paddingClass : "pt-8 pb-3"}  flex justify-center w-16 rounded-tr-btn rounded-br-btn`}>
                    <div className='h-6 flex items-center'>
                        {icon}
                    </div>
                </div> : null}
        </div>
    )
}

export default Input
