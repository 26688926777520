import React, { useEffect, useState } from 'react';
import logo from "../../../assets/img/Logos.png";
import { Back, Button,ToolsBar } from '../../customComponenets';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { clientInfos, logout } from '../../../features/clientSlice';
import { GetClientByCode, GetContainer } from '../../../requests/Client';
import Loader from 'react-loader-spinner';
import { Download } from '../../customComponenets/Icons';


const DownloadFiles = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const clientdetails = useAppSelector(clientInfos)
    const [loading, setLoading] = useState(true)
    const [dateCreation, setDateCreation] = useState(null)
    const [datePreparation, setDatePreparation] = useState(null)
    const [dateVoyage, setDateVoyage] = useState(null)
    const [dateExpect, setDateExpect] = useState(null)
    const [dateNavire, setDateNavire] = useState(null)
    const [dateArrive, setDateArrive] = useState(null)
    const [containerData, setContainerData] = useState([])
    const [containerComment, setContainerComment] = useState([])
    const [code, setCode] = useState(null)

    const COLUMNS = [
        /*  {
             Header: 'Status',
             accessor: 'dateArrive',
             Cell: ({ row }) => (
                 <div className='flex justify-center items-center min-w-300' >
                     <span className="flex justify-center md:ml-3  px-3 items-center h-10 text-white bg-warning rounded-btn" >
                         {dateArrive ? "Arrivé" : "En Cours"} </span>
                 </div>),
         }, */
        {
            Header: 'Priorité',
            accessor: 'priority',
            Cell: ({ row }) => (
                <div className='flex justify-center items-center min-w-300' >
                    <p>{(new Date(row.original.priority)).toLocaleDateString('en-GB')}</p>
                </div>),
        },
        {
            Header: 'Libellé',
            accessor: 'libelle',
        },
        {
            Header: 'Descriptif',
            accessor: 'description',
        },
        {
            Header: 'Qté. Annoncée',
            accessor: 'quantityAnnounced',
        },
        {
            Header: 'Vol. (m3)',
            accessor: 'volume',
        },

    ]


    useEffect(() => {
        GetContainer(clientdetails.code_client, clientdetails.code_suivi)
            .then((res) => {
                setCode(res.data.code)
                setContainerData(res.data.commandes)
                setDateCreation(res.data.dateCreation ? res.data.dateCreation : null)
                setDatePreparation(res.data.datePreparation ? res.data.datePreparation : null)
                setDateVoyage(res.data.dateVoyage ? res.data.dateVoyage : null)
                setDateNavire(res.data.dateNavire ? res.data.dateNavire : null)
                setDateArrive(res.data.dateArrive ? res.data.dateArrive : null)
                setDateExpect(res.data.dateExpectedArrival ? res.data.dateExpectedArrival : "")
                setContainerComment(res.data.comments)
                setLoading(false);
            })
    }, [])


    return (
        <div className='bg-client bg-cover h-screen'>
            <div className='h-full flex flex-col justify-evenly items-center'>
                <p className='text-sm text-center text-darkgrey font-bold'>Un problème urgent concernant votre livraison ? Contactez-nous au <span className='text-primary'>01 48 11 68 68</span></p>
                {/* re add opacity */}
                <div className="lg:w-5/6 md:w-11/12 w-full h-4/5 overflow-hidden  text-center rounded">
                    <OverlayScrollbarsComponent
                        options={{ scrollbars: { autoHide: 'leave' } }}
                        style={{ height: "100%", width: "100%", maxHeight: "110%", maxWidth: "100%" }}>
                        <div className='flex flex-wrap h-full'>


                            <div className=' lg:w-5/6 xl:w-4/5 w-full h-full md:pr-4'>
                                <div className='bg-white rounded w-full  h-full overflow-hidden '>
                                    <OverlayScrollbarsComponent
                                        options={{ scrollbars: { autoHide: 'leave' } }}
                                        className='deviant-scrollbars' style={{ height: "100%", width: "100%", maxHeight: "110%", maxWidth: "100%" }}>
                                        <div className='y-80 py-12 2xl:px-24 xl:px-12 lg:px-6 md:px-6 px-2  flex flex-col items-center justify-between'>
                                            <div className="flex w-full xl:flex-row mb-5 flex-col justify-between  items-center">
                                                <div className="flex w-1/6  xl:justify-start items-center justify-center">
                                                    <Button label='Retour' variant='primary' left transparent icon={<Back />} onClick={() => {
                                                        navigate("/conteneur")
                                                    }} />
                                                </div>
                                                <div className="w-4/6 text-center">
                                                    <h1 className='text-2xl font-bold text-center"'>
                                                    Téléchargez les documents pour votre déménagement
                                                    </h1>
                                                </div>
                                                <div className="w-1/6">
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div className="flex flex-col items-start px-8 justify-between">

                                            <a className='block w-full border p-2 font-medium text-base text-darkgrey mb-4' href="https://2sage-alba.fr/wp-content/uploads/Attestation-de-deplacement-derogatoire.pdf">ATTESTATION DE DEPLACEMENT DEROGATOIRE  <span className='bg-primary text-white p-1 rounded-md'>PDF</span></a>
                                            <a className='block w-full border p-2 font-medium text-base text-darkgrey mb-4' href="https://2sage-alba.fr/wp-content/uploads/DECLARATION-DE-VALEUR.pdf">DECLARATION DE VALEUR  <span className='bg-primary text-white p-1 rounded-md'>PDF</span></a>
                                            <a className='block w-full border p-2 font-medium text-base text-darkgrey mb-4' href="https://2sage-alba.fr/wp-content/uploads/DECLARATION-SUR-LHONNEUR-REFUS-ASSURANCE-EFFETS-PERSONNELS-Copie.pdf">DECLARATION SUR L’HONNEUR – REFUS ASSURANCE EFFETS PERSONNELS  <span className='bg-primary text-white p-1 rounded-md'>PDF</span></a>
                                            <a className='block w-full border p-2 font-medium text-base text-darkgrey mb-4' href="https://2sage-alba.fr/wp-content/uploads/DECLARATION-SUR-LHONNEUR-REFUS-ASSURANCE-VEHICULEl.pdf">DECLARATION SUR L’HONNEUR – REFUS ASSURANCE VEHICULE  <span className='bg-primary text-white p-1 rounded-md'>PDF</span></a>
                                            <a className='block w-full border p-2 font-medium text-base text-darkgrey mb-4' href="https://2sage-alba.fr/wp-content/uploads/FICHE-DE-RENSEIGNEMENT-GARDE-MEUBLES.pdf">FICHE DE RENSEIGNEMENT GARDE-MEUBLES  <span className='bg-primary text-white p-1 rounded-md'>PDF</span></a>
                                            <a className='block w-full border p-2 font-medium text-base text-darkgrey mb-4' href="https://2sage-alba.fr/wp-content/uploads/MANDAT-SEPA-GARDE-MEUBLES.pdf">MANDAT SEPA GARDE-MEUBLES  <span className='bg-primary text-white p-1 rounded-md'>PDF</span></a>
                                            <a className='block w-full border p-2 font-medium text-base text-darkgrey mb-4' href="https://2sage-alba.fr/wp-content/uploads/GUADELOUPE-EFFETS.pdf">GUADELOUPE EFFETS  <span className='bg-primary text-white p-1 rounded-md'>PDF</span></a>
                                            <a className='block w-full border p-2 font-medium text-base text-darkgrey mb-4' href="https://2sage-alba.fr/wp-content/uploads/GUADELOUPE-VEHICULE.pdf">GUADELOUPE VEHICULE  <span className='bg-primary text-white p-1 rounded-md'>PDF</span></a>
                                            <a className='block w-full border p-2 font-medium text-base text-darkgrey mb-4' href="https://2sage-alba.fr/wp-content/uploads/LA-GUYANE-EFFETS.pdf">LA GUYANE EFFETS  <span className='bg-primary text-white p-1 rounded-md'>PDF</span></a>
                                            <a className='block w-full border p-2 font-medium text-base text-darkgrey mb-4' href="https://2sage-alba.fr/wp-content/uploads/LA-GUYANE-VEHICULE.pdf">LA GUYANE VEHICULE  <span className='bg-primary text-white p-1 rounded-md'>PDF</span></a>
                                            <a className='block w-full border p-2 font-medium text-base text-darkgrey mb-4' href="https://2sage-alba.fr/wp-content/uploads/LA-REUNION-VEHICULE.pdf">LA REUNION VEHICULE  <span className='bg-primary text-white p-1 rounded-md'>PDF</span></a>
                                            <a className='block w-full border p-2 font-medium text-base text-darkgrey mb-4' href="https://2sage-alba.fr/wp-content/uploads/LA-REUNNION-EFFETS.pdf">LA REUNION EFFETS  <span className='bg-primary text-white p-1 rounded-md'>PDF</span></a>
                                            <a className='block w-full border p-2 font-medium text-base text-darkgrey mb-4' href="https://2sage-alba.fr/wp-content/uploads/MARTINIQUE-EFFETS.pdf">MARTINIQUE EFFETS  <span className='bg-primary text-white p-1 rounded-md'>PDF</span></a>
                                            <a className='block w-full border p-2 font-medium text-base text-darkgrey mb-4' href="https://2sage-alba.fr/wp-content/uploads/MARTINIQUE-VEHICULE.pdf">MARTINIQUE VEHICULE  <span className='bg-primary text-white p-1 rounded-md'>PDF</span></a>

                                        </div>

                                    </OverlayScrollbarsComponent>
                                </div>
                            </div>
                            <div className='lg:w-1/6 xl:w-1/5 w-full h-full'>
                                <ToolsBar />
                            </div>
                        </div>
                    </OverlayScrollbarsComponent>
                </div>
                <img className='w-36' src={logo} alt='logo' />
            </div>
        </div >
    );
}
export default DownloadFiles