import React, { useEffect, useState } from 'react';
import logo from "./../../../assets/img/Logos.png";
import { Back, Button, Comments, Input, Modal, ShutDown, Steper, Table } from '../../customComponenets';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { logout, userInfos } from '../../../features/userSlice';
import { GetOneContainer, ValidateContainer } from '../../../requests/Container';
import { SuccesfulyUpdated, SomthingWentWrong, SuccesfulyAdded } from '../../../alerts';
import { AddComment } from '../../../requests/Comment';
import { GetAgent } from '../../../requests/Agent';
import { date } from 'yup/lib/locale';
import Loader from 'react-loader-spinner';


const ContainerDetails = () => {
    const dispatch = useAppDispatch();
    //let { id } = useParams();
    const { state } = useLocation();

    // coment modal
    const [commnetModal, setCommnetModal] = useState(false)
    // comnet data
    const [comment, setComment] = useState("")
    const [dateCreation, setDateCreation] = useState(null)
    const [datePreparation, setDatePreparation] = useState(null)
    const [dateVoyage, setDateVoyage] = useState(null)
    const [dateNavire, setDateNavire] = useState(null)
    const [dateArrive, setDateArrive] = useState(null)
    const [dateArriveManuel, setDateArriveManuel] = useState(null)
    const [dateExpect, setDateExpect] = useState(null)
    const [containerData, setContainerData] = useState([])
    const [containerComment, setContainerComment] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [code, setCode] = useState("")
    const userdetails = useAppSelector(userInfos)
    const [loading, setLoading] = useState(true)

    // saving states  
    const [savingComment, setSavingComment] = useState(false)
    const [changeingState, setChangeingState] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        if (state != null || state != undefined) {
            GetOneContainer(parseInt(state.id))
                .then(res => {

                    setCode(res.data.code)
                    setContainerData(res.data.commandes)
                    setDateCreation(res.data.dateCreation ? res.data.dateCreation : null)
                    setDatePreparation(res.data.datePreparation ? res.data.datePreparation : null)
                    setDateVoyage(res.data.dateVoyage ? res.data.dateVoyage : null)
                    setDateNavire(res.data.dateNavire ? res.data.dateNavire : null)
                    setDateArrive(res.data.dateArrive ? res.data.dateArrive : null)
                    if (res.data.dateExpectedArrival) {

                        let expecteddate = new Date(res.data.dateExpectedArrival)

                        setDateExpect(expecteddate.toLocaleDateString("fr-CA"))
                    } else {
                        setDateExpect(null)

                    }
                    setContainerComment(res.data.comments)
                    setLoading(false)
                })
                .catch(err => {
                    SomthingWentWrong()
                })
        } else {
            navigate("/")
        }
    }, [state, refresh])

    const COLUMNS = [
        /* {
            Header: 'Status',
            accessor: 'dateArrive',
            Cell: ({ row }) => (
                <div className='flex justify-center items-center min-w-300' >
                    <span className="flex justify-center md:ml-3  px-3 items-center h-10 text-white bg-warning rounded-btn" >
                        {dateArrive ? "Arrivé" : "En Cours"} </span>
                </div>),
        }, */
        {
            Header: 'PRIORITE',
            accessor: 'priority',
            Cell: ({ row }) => (
                <div className='flex justify-center items-center min-w-300' >
                    <p>{(new Date(row.original.priority)).toLocaleDateString('en-GB')}</p>
                </div>),
        },
        {
            Header: 'Client',
            accessor: 'client',
            Cell: ({ row }) => (
                <div className='flex justify-center items-center min-w-300'>
                    <p>{row.original.fullname}</p>
                </div>),
        },
        {
            Header: 'Libellé',
            accessor: 'libelle',
        },
        {
            Header: 'Descriptif',
            accessor: 'description',
        },
        {
            Header: 'Qté. Annoncée',
            accessor: 'quantityAnnounced',
        },
        {
            Header: 'qté chargée',
            accessor: 'quantityCharged',
        },
        {
            Header: 'zone de chargement',
            accessor: 'zone',
        },
        {
            Header: 'Vol.(m3)',
            accessor: 'volume',
        },
        {
            Header: 'Poids(kg)',
            accessor: 'poids',
        },
        {
            Header: 'commentaires',
            accessor: 'commentaire',
        }
    ]

    const validateState = (etat: string, dateExpect, dateArriveMa) => {
        setChangeingState(true)
        ValidateContainer(parseInt(state.id), etat, dateExpect, dateArriveMa)
            .then(res => {
                setChangeingState(false)

                setRefresh(!refresh);
                if (etat === "preparation") {
                    SuccesfulyUpdated("L'état du contenaire est maintenant en préparation")
                }
                if (etat === "voyage") {
                    SuccesfulyUpdated("L'état du contenaire est maintenant en voyage")
                }
                if (etat === "navire") {
                    SuccesfulyUpdated("L'état du contenaire est embarqué sur navire")
                }
                if (etat === "arrivee") {
                    SuccesfulyUpdated("L'état du contenaire est maintenant arrivé")
                }
            })
            .catch(err => {
                setChangeingState(false)
                console.log("error ::", err);
            })
    }

    const sendComment = () => {
        setSavingComment(true)
        GetAgent(userdetails.email)
            .then(agentsRes => {

                if (agentsRes.data[0]) {
                    let addBody = {
                        "message": comment,
                        "agent": { "id": agentsRes.data[0].id }
                        ,
                        "conteneur": {
                            "id": parseInt(state.id)
                        }
                    }
                    AddComment(addBody)
                        .then(res => {
                            setSavingComment(false)
                            containerComment.push(res.data);
                            setComment("");
                            setCommnetModal(false);
                            SuccesfulyAdded("Commentaire ");
                        })
                        .catch(err => {
                            console.log("an error occured", err);
                            SomthingWentWrong();
                            setSavingComment(false)
                        })
                } else {
                    setSavingComment(false)
                    console.log("no available user by id");
                }
            })
            .catch(err => {
                SomthingWentWrong();
                setSavingComment(false)
                console.log("cant find agent :", err);
            })



    }
    return (
        <div className='bg-client bg-cover h-screen'>
            <div className='h-full flex flex-col justify-evenly items-center'>
                <div className="bg-white bg-opacity-80  overflow-hidden  lg:w-9/12 md:w-11/12 w-full h-4/5 rounded text-center ">
                    <OverlayScrollbarsComponent
                        options={{ scrollbars: { autoHide: 'leave' } }}
                        className='deviant-scrollbars' style={{ height: "100%", width: "100%", maxHeight: "110%", maxWidth: "100%" }}>
                        <div className='y-80 py-12 2xl:px-24 xl:px-12 lg:px-6 md:px-6 px-2  flex flex-col items-center justify-between'>
                            <div className="flex w-full xl:flex-row mb-5 flex-col justify-between  items-center">
                                <div className="flex  xl:justify-start items-center justify-center">
                                    <Button label='Retour' variant='primary' left transparent icon={<Back />} onClick={() => {
                                        navigate("/conteneur")
                                    }} />
                                </div>
                                <div className="flex  xl:flex-row flex-col  xl:items-center xl:justify-start justify-center">
                                    <h1 className='flex justify-center items-center text-2xl font-bold'>
                                        Conteneur n° {code ? code : <Loader type="Oval" color="#b5b5b5" height={20} width={20} />}
                                    </h1>
                                    <span className={`flex justify-center md:ml-3  px-5 items-center h-10 text-white bg-warning rounded-btn`}>
                                        {code ? dateArrive ? "Arrivé" : "En Cours" : <Loader type="Oval" color="#fff" height={20} width={20} />} </span>
                                </div>
                                <div className="flex  xl:justify-end items-center justify-center">
                                    <Button label='Déconnexion' variant='primary' transparent icon={<ShutDown />} onClick={() => {
                                        navigate("/admin20alba21")
                                        dispatch(logout())
                                    }} />
                                </div>
                            </div>
                            <Steper DateCration={dateCreation} DatePreparation={datePreparation} DateVoyage={dateVoyage} dateNavire={dateNavire} DateArrive={dateArrive} dateExpect={dateExpect} />
                            <Table title='Mes commandes :' COLUMNS={COLUMNS} DATA={containerData} loading={loading} maxHeight='500px' />
                            <div className='flex w-full xl:flex-col mb-5 flex-col justify-between  items-center'>

                                {containerComment.length > 0 ?
                                    <Comments title='Remarque livraison :' comments={containerComment} />
                                    : null
                                }
                                <div className='flex flex-col w-full  mb-5 '>
                                    <div className='flex xl:flex-row  flex-col justify-between   mb-5  items-center  w-full md:pr-3 '>

                                        <span className='text-primary   lg:text-left text-center mr-2 lg:mb-0  mb-5'>
                                            Envoyer un message d'information aux clients de ce conteneur
                                        </span>
                                        <div>
                                            <Button label='Commenter' variant='info' transparent border onClick={() => {
                                                setCommnetModal(true)
                                            }} />
                                        </div>
                                    </div>
                                    {!dateArrive ? <div className='flex xl:flex-row flex-col   items-center justify-between  mb-5 w-full  md:pr-3'>
                                        <span className='text-primary lg:text-left text-center mr-2 lg:mb-0 '>
                                            Date d'arrivée prévue :
                                        </span>
                                        <Input
                                            paddingClass='py-2'
                                            margintopClass='mt-0'
                                            label=""
                                            iconBgColor='bg-warning'
                                            value={dateExpect}
                                            type="date"
                                            onChange={e => {
                                                setDateExpect(e.target.value)

                                            }}
                                        />

                                    </div> : null}
                                    {(dateVoyage && !dateArrive) ? <div className='flex xl:flex-row flex-col   items-center justify-between  mb-5 w-full  md:pr-3'>
                                        <span className='text-primary lg:text-left text-center mr-2 lg:mb-0 '>
                                            Date d'arrivée:
                                        </span>
                                        <Input
                                            paddingClass='py-2'
                                            margintopClass='mt-0'
                                            label=""
                                            iconBgColor='bg-warning'
                                            value={dateArriveManuel}
                                            type="date"
                                            onChange={e => {
                                                setDateArriveManuel(e.target.value)
                                            }}
                                        />

                                    </div> : null}

                                    <div >
                                        {!dateArrive ? <div className="flex xl:justify-end lg:my-0  my-5 justify-center md:pr-3">
                                            <Button label="VALIDER L'ÉTAPE EN COURS" variant='succes' onClick={() => {
                                                if ((dateCreation != null) && (datePreparation == null)) {
                                                    validateState("preparation", dateExpect ? new Date((new Date(dateExpect)).setHours(10)) : null, dateArriveManuel);
                                                } else {
                                                    if ((datePreparation != null) && (dateNavire == null)) {
                                                        validateState("navire", dateExpect ? new Date((new Date(dateExpect)).setHours(10)) : null, dateArriveManuel);
                                                    } else {
                                                        if ((dateNavire != null) && (dateVoyage == null)) {
                                                            validateState("voyage", dateExpect ? new Date((new Date(dateExpect)).setHours(10)) : null, dateArriveManuel);
                                                        } else {
                                                            if ((dateVoyage != null) && (dateArriveManuel != null)) {
                                                                validateState("arrivee", dateExpect ? new Date((new Date(dateExpect)).setHours(10)) : null, new Date((new Date(dateArriveManuel)).setHours(10)));
                                                            } else {
                                                                console.log("cant update status");
                                                            }
                                                        }

                                                    }
                                                }

                                            }} loading={changeingState} />
                                        </div> : null}
                                    </div>

                                </div>
                            </div>
                        </div>

                    </OverlayScrollbarsComponent>
                </div>
                <img className='w-36' src={logo} alt='logo' />
            </div>

            <Modal
                title="Ajouter message d'information"
                show={commnetModal}
                close={() => { setCommnetModal(false); }}
                footer={
                    <div className='flex lg:justify-end justify-center'>
                        <div className="ml-2">
                            <Button onClick={sendComment
                            } label='Valider' variant='succes' loading={savingComment} />
                        </div>
                        <div className="ml-2">
                            <Button onClick={() => {
                                setCommnetModal(false)
                            }} label='Fermer' variant='primary' />
                        </div>
                    </div>}
            >

                <div className='p-5'>
                    <div className='flex flex-col'>
                        <label className="block text-sm font-medium text-gray-700 my-2">Comment</label>
                        <textarea className="w-full bg-lightgrey  p-2 rounded-lg focus:outline-none" rows={6}
                            placeholder="tapez un commentaire pour tous les clients concernés par ce conteneur" x-ref="textarea"
                            value={comment} onChange={(e) => { setComment(e.target.value) }} />

                    </div>
                </div>
            </Modal >
        </div >
    );
}
export default ContainerDetails