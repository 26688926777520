import Swal from "sweetalert2"

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

export const SomthingWentWrong = () => {
    Toast.fire({
        icon: 'error',
        title: 'Quelque chose a mal tourné'
    })
}

export const CustomError = (msg: string) => {
    Toast.fire({
        icon: 'warning',
        title: 'Quelque chose a mal tourné',
        text: msg
    })
}

export const SMSnotSent = () => {
    Toast.fire({
        icon: 'warning',
        title: 'Contenaire ajouté(e) avec succès',
        text: "Certains utilisateurs n'ont pas reçu des messages SMS, leur numéro de téléphone n'étant pas valide."
    })
}



export const InvalidCredentials = (type) => {
    Toast.fire({
        icon: 'warning',
        title: type === "agent" ? "votre Email ou mot de passe sont incorrects" : "votre Code Client ou Code de suivi sont incorrects",
    })
}

